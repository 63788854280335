import ProTable from "@ant-design/pro-table";
import { message, Tag } from "antd";
import { useRef } from "react";

import marbleApi from "../../services/http/marbleApi";

export default function Logs(props) {
  const actionRef = useRef();
  const { userId } = props;
  const columns = [
    {
      title: "Action",
      dataIndex: "text",
      key: "name",
      render: (text, data) => (
        <Tag color={data.type || "processing"}>
          <strong>{text}</strong>
        </Tag>
      ),
    },
    {
      title: "Resource",
      dataIndex: "url",
      key: "age",
      width: '200px',
      render: (text) => <button type="button">{text}</button>,
    },
    {
      title: "Detail",
      dataIndex: "description",
      valueType: "textarea",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      sorter: true,
    },
  ];

  const listLogs = async (params, options) => {
    if (userId) params.userId = userId;

    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.utils.listLogs,
        {
          params,
          options,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };
  return (
    <ProTable
      headerTitle={null}
      tableLayout="fixed"
      actionRef={actionRef}
      rowKey="key"
      search={false}
      options={{
        fullScreen: false,
        reload: false,
        density: false,
        setting: false,
      }}
      request={listLogs}
      columns={columns}
    />
  );
}
