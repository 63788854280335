import { Space,Popconfirm } from "antd";
import CartView from "../Cart";
import Avatar from "./AvatarDropdown";
import { QuestionCircleOutlined, YoutubeFilled } from "@ant-design/icons";



const HeaderRightContent = () => {
  return (
    <Space className="right">
      <span style={{ marginRight: "0.2em"}}>
      <Popconfirm
          title={
            <a 
              href="https://www.youtube.com/playlist?list=PLKf3w1m_3X9Rs-ZWn5xEOonxjfMFXVegB"
              target="_blank"
              rel="noreferrer"
            >
              Tutorials
            </a> 
          }
          showCancel={false}
          showOk={false}
          icon={<YoutubeFilled style={{ fontSize: 20, color: 'red' }} />}
          okButtonProps={{
            hidden: true,
          }}
        >
          <QuestionCircleOutlined style={{ fontSize: 20, marginTop: "1em" }} />
      </Popconfirm>
      </span>
      <span style={{ marginRight: "1em" }}>
        <CartView />
      </span>
      <Avatar menu />
    </Space>
  );
};

export default HeaderRightContent;
