/* eslint-disable no-template-curly-in-string */
import {
  PlusCircleTwoTone,
  ShoppingTwoTone,
  StarTwoTone,
} from "@ant-design/icons";
import {
  ProFormSelect,
  ProFormTextArea,
  StepsForm,
  ProFormDatePicker
} from "@ant-design/pro-form";
import { Button, Card, message, Tabs } from "antd";
import { useEffect, useState } from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../helpers";
import { checkingTAX } from "../../../helpers/order";
import marbleApi from "../../../services/http/marbleApi";
import ProductsTab from "../components/ProductsTab";
import SpecialProducts from "../components/SpecialProducts";
import LinkClient from "./LinkClient";
import "../style.less";

const { TabPane } = Tabs;

function CreateNewOrder() {
  // Order details

  const [client, setClient] = useState(null);
  const [products, setProducts] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [holding, setHolding] = useState([]);
  const [zohoAccount, setZohoAccount] = useState(null);
  const [company, setComapany] = useState({Account_Name: null, id: null});

  // Order detail end

  const navigate = useNavigate();

  useEffect(() => {
    if (client?.zohoAccountId) {
      async function getAccount() {
        try {
          const response = await marbleApi.http.post(
            marbleApi.urls.zoho.getAccount(client.zohoAccountId)
          );
          if (response) {
            setComapany({Account_Name: response.Account_Name, id: response.id})
          }
        } catch (error) {
          message.error(error?.message);
        }
      }
      getAccount()
    }
  }, [client]);

    // search Account
    const searchAccount = async (domain) => {
      try {
        if (domain.keyWords) {
          const response = await marbleApi.http.post(
            marbleApi.urls.zoho.searchAccount,
            {
              data: domain?.keyWords,
            }
          );
          if (response?.length > 0) {
            return response.map((item) => {
              return {
                value: item.id,
                label: item.Account_Name,
              };
            });
          }
        }
      } catch (error) {
        message.error(error)
        return [];
      }
    }

  
  return (
      <PageContainer title="StonePro" className="pageHeader">
      <Card
        title={
          <span>
            <PlusCircleTwoTone /> &nbsp;&nbsp; Create New Order
          </span>
        }
      >
        <StepsForm
          containerStyle={{minWidth: 0, maxWidth:"80vw"}}
          onFinish={async (values) => {
            // create order
            const order = {};

            order.userId = getUser().id;
            order.slabs = products;
            order.note = values.note;
            order.description = values.description;
            order.status = "draft";
            order.closingDate = values.closingDate;
            order.stage = 0;
            order.zohoCompany = company
            order.tax = checkingTAX(getUser(), client);
            if (client) {
              order.clientId = client?.id ;
            }

            if (!getUser().location) {
              message.error("Please add location");
            }
            else {
              const hide = message.loading("Creating order");
              try {
                const newOrder = await marbleApi.http.post(
                  marbleApi.urls.order.create,
                  {
                    data: order,
                    hold: holding,
                    specialProducts:specialProducts
                  }
                );
                if (newOrder) {
                  if (newOrder==='Duplicate') {
                    message.warning("Duplicate opportunity name");
                  }
                  else {
                    message.success("Order created.");
                    navigate(`/orders/${newOrder.id}`);
                    return true;
                  }
                  hide();
                }
             
              } catch (error) {
                hide();
                message.error(error.message);
                return false;
              }
            }
          }}
          submitter={{
            render: ({ form, onSubmit, step, onPre }) => {
              return [
                  step === 0 && (
                  <Button
                    key="skip"
                    onClick={() => {
                      setClient(null)
                      onSubmit?.();
                    }}
                  >
                    Skip
                  </Button>),
                step > 0 && (
                  <Button
                    key="pre"
                    onClick={() => {
                      onPre?.();
                    }}
                  >
                    Previous
                  </Button>
                ),
                <Button
                  key="next"
                  disabled={getUser().zohoID && !zohoAccount && client}
                  type="primary"
                  onClick={() => {
                    onSubmit?.();
                  }}
                >
                  Next
                </Button>,
              ];
            },
          }}
          formProps={{
            validateMessages: {
              required: "${label} is required!",
              types: {
                email: "${label} is not a valid email!",
                number: "${label} is not a valid number!",
              },
              number: {
                range: "${label} must be between ${min} and ${max}",
              },
            },
          }}
          style={{ width: 300 }}
        >
          {/* Client Information */}

          <StepsForm.StepForm
            name="client"
            title="Contact Information"
            onFinish={async (fields) => {
              return true;
            }}
            style={{ marginBottom: 40 }}
          >
            <LinkClient setClient={setClient} client={client} zohoAccount={zohoAccount} setZohoAccount={setZohoAccount} />
          </StepsForm.StepForm>

          {/* Materials */}

          <StepsForm.StepForm
            name="materials"
            title="Materials"
            onFinish={async () => {
              if (products.length === 0 && specialProducts.length === 0) {
                message.error("Please add at least one product");
                return false;
              }
              return true;
            }}
          >
            <Tabs defaultActiveKey="1" style={{ marginBottom: 40 }}>
              <TabPane
                tab={
                  <span>
                    <ShoppingTwoTone /> Products
                  </span>
                }
                key="1"
              >
                <ProductsTab callback={setProducts} setHolding={setHolding} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <StarTwoTone /> Special Products
                  </span>
                }
                key="2"
              >
                <SpecialProducts callback={setSpecialProducts} />
              </TabPane>
            </Tabs>
          </StepsForm.StepForm>

          {/* Complete */}

          <StepsForm.StepForm name="complete" title="Complete">
            <ProFormTextArea
              name="description"
              label="Opportunity name / Deal"
              placeholder=""
              rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              fieldProps={{ rows: 2 }}
            />
            <ProFormTextArea
              name="note"
              label="Note"
              width={"60vw"}
              placeholder=""
              fieldProps={{ rows: 6 }}
            />
            <ProFormDatePicker
              name="closingDate"
              label="Closing Date" 
              rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
            />
            <ProFormSelect
              showSearch
              name="company"
              label="Company"
              fieldProps={{ labelInValue: true }}
              placeholder={company?.Account_Name}
              debounceTime={500}
              request={searchAccount}
              onChange={(selected) => {
                setComapany({Account_Name: selected.label, id: selected.value});
              }}
            />
          </StepsForm.StepForm>
        </StepsForm>
      </Card>
        </PageContainer>
  );
}

export default CreateNewOrder;
