import { Button, Result } from "antd";
import React from "react";

const NoAccess = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <Button type="primary" onClick={() => false }>
        Back Home
      </Button>
    }
  />
);

export default NoAccess;
