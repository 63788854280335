import { Navigate, Outlet } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers";

const ProtectedRoute = () => {
  const redirectPath = "/login";
  const user = getFromLocalStorage("user");

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
