import { ProFormSelect } from "@ant-design/pro-components";
import {Skeleton ,Empty , message, Col, Alert, Button, Row, Modal,TreeSelect } from "antd";
import { useState,useEffect } from "react";
import marbleApi from "../../../services/http/marbleApi";
import AddClient from "./AddClient";
import LinkAccount from "./LinkAccount";
import SearchFromAccount from "./SearchFromAccount";
import { debounce } from "../../../helpers";
import { uniqBy } from "lodash";

function LinkClient(props) {
  const { client, setClient, zohoAccount, setZohoAccount } = props;
  const [id, setID] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [searchAccountPreview, setSearchAccountPreview] = useState(false);
  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [noContent, setNoContent] = useState(<Skeleton />);
  const [createModalVisible, handleModalVisible] = useState(false);

  
  useEffect(() => {
    getClientsTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // searching lead convertLead
  const seachLead = async (search) => {
    if (search.keyWords) {
      try {
        const response = await marbleApi.http.post(marbleApi.urls.zoho.search, {
          data: `((Email:starts_with:${search.keyWords})or(Full_Name:starts_with:${search.keyWords}))`,
        });
        if (response) {
          return response.map((item) => {
            return {
              value: item.id,
              label: `${item.Email} || ${item.Full_Name}`,
            };
          });
        }
      } catch (error) {
        message.error(error?.message);
        return [];
      }
    }
  };

  // convert lead
  const convertLead = async () => {
    const hide = message.loading("converting...");
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.zoho.convertLead,
        {
          id,
        }
      );
      if (response) {
        hide();
        setClient(response);
        setPreviewVisible(false);
      }
    } catch (error) {
      hide();
      message.error(error?.message);
    }
  };


  const loadContent = <Skeleton />;
  const contentNotFound = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  // search Account
  const searchAccount = async (domain) => {
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.zoho.mergeTree,
        {
          data: domain,
        }
      );
      if (response?.accounts) {
        const zohoAccounts = response.accounts.map((item) => {
          return {
            id: item?.id,
            pId: 0,
            value: item?.id,
            title: item?.Account_Name,
            isLeaf: false,
            selectable: false,
          };
        });
        const zohoContacts = response.contacts.map((item) => {
          return {
            id: item?.id,
            pId: item?.Account_Name?.id || 0,
            value: item?.id,
            title: item?.Full_Name,
            isLeaf: true,
            selectable: true,
          };
        });
        setContacts(response.contacts);
        const updateTreeData = [...zohoAccounts, ...zohoContacts];
        const _treeData = treeData;
        _treeData.push(...updateTreeData)
        setTreeData(_treeData);
      }
      setNoContent(contentNotFound);
    } catch (error) {
      return [];
    }
  };

  // get clients
  const getClientsTree = async (search) => {
    setNoContent(loadContent)
    const params = {
      current: 1,
      pageSize: 20,
    };
    try {
      if (search) {
        params.name = search;
        if (search.length > 2) {
           await searchAccount(search);
        }
      }
      const response = await marbleApi.http.post(marbleApi.urls.client.list, {
        params,
        options: {},
      });
      if (response) {
        setClients([...clients, ...response.data]);
        const myClients = response.data.map((item) => {
          return {
            id: item?.id,
            pId: 0, 
            value: item?.id,
            title: item?.name,
            isLeaf: true,
            selectable: true,
          };
        });
        treeData.push(...myClients)
      }
    } catch (error) {
      message.error(error?.message);
      return [];
    }
  };

  // Select Contact
  const selectContact = async (contact) => {
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.zoho.selectContact,
        {
          contact,
        }
      );
      if (response) {
        setClient(response);
        setZohoAccount(response.zohoAccountId);
        setSearchAccountPreview(false);
      } else {
        setZohoAccount(null);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const onSelect = async (value) => {
    const allContacts = [...clients, ...contacts];
    let client = allContacts.find((item) => item.id === value);
    if (client) {
      if (client?.source) {
        setClient(client);
        if (client.zohoID) {
          setZohoAccount(client.zohoAccountId);
        } else {
          setZohoAccount(null);
        }
      } else {
        await selectContact(client);
      }
    }
  };

  return (
    <>
      <Row>
        <Col>
          <TreeSelect
            treeDataSimpleMode
            style={{
              minWidth:"300px",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
              minWidth: 300,
            }}
            notFoundContent={noContent}
            showSearch
            treeNodeFilterProp="title"
            onSearch={debounce(getClientsTree, 1000)}
            placeholder="Please select"
            onSelect={onSelect}
            treeData={uniqBy(treeData,'id')}
          />
        </Col>
        <Col
          className="new_order_column"
          style={{
            alignSelf: "center",
          }}
        >
          <Button
            onClick={() => {
              handleModalVisible(true);
            }}
            type="link"
            key="1"
          >
            Add New Contact
          </Button>
          <Button
            onClick={() => {
              setPreviewVisible(true);
            }}
            type="link"
            key="2"
          >
            Search from zoho Lead
          </Button>
        </Col>
      </Row>

      <br></br>
      {client ? (
        <>
          <Alert
            showIcon={false}
            className="new_order_column"
            type="warning"
            banner
            message={
              <div>
                <i>
                  {client?.name} / {client?.group || "N/A"}
                </i>
                <br></br>
                <i>
                  {client?.email} / {client?.phone}
                </i>
                <br></br>
                <i>{client?.shipping_address_text}</i>
              </div>
            }
          />
          <br></br>
          {zohoAccount ? (
            <Alert type="info" message="Account Linked" />
          ) : (
            <LinkAccount
              name={client.name}
              email={client.email}
              setZohoAccount={setZohoAccount}
              client={client}
            />
          )}
        </>
      ) : null}
      <AddClient
        handleModalVisible={handleModalVisible}
        createModalVisible={createModalVisible}
        mode="add"
        currentRow={null}
        setupdated={() => {}}
      />
      <SearchFromAccount
        searchAccountPreview={searchAccountPreview}
        setSearchAccountPreview={setSearchAccountPreview}
        setClient={setClient}
        setZohoAccount={setZohoAccount}
      />
      <Modal
        opened={previewVisible}
        title="Search Lead"
        okText="Next"
        onOk={convertLead}
        onCancel={() => {
          setPreviewVisible(false);
        }}
        width="60vw"
      >
        <ProFormSelect
          showSearch
          name="id"
          label="Search Name or Email"
          fieldProps={{
            labelInValue: true,
          }}
          debounceTime={500}
          request={seachLead}
          onChange={(selected) => {
            setID(selected.value);
          }}
        />
      </Modal>
    </>
  );
}

export default LinkClient;
