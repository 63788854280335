import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProFormCheckbox,
  ProFormText,
} from "@ant-design/pro-components";
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../../components/Layout/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  waitTime,
} from "../../../helpers";
import marbleApi from "../../../services/http/marbleApi";
import AccountSetUp from "../components/AccountSetUp";
import styles from "./index.less";

const Login = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect");

  const user = getFromLocalStorage("user");
  if (user && !redirect) {
    navigate("/");
  }

  const handleSubmit = async (values) => {
    try {
      localStorage.clear();
      const response = await marbleApi.http.post(
        marbleApi.urls.auth.login,
        values
      );
      if (response) {
        saveToLocalStorage("user", response);
        await waitTime(300);
        navigate(redirect || "/");
        return;
      }
    } catch (error) {
      message.error(error?.message);
    }
  };

  const forgotPassword = async () => {
    if (!email) {
      message.error("Please enter your email address");
      return;
    }
    message.loading("Sending password reset link...");
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.auth.forgotPassword,
        { email }
      );
      if (response) {
        message.success("Password reset email sent. Please check your email.");
      }
    } catch (error) {
      message.error(error?.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <LoginForm
          id="login-form"
          logo={<img alt="logo" className="logo" src="/logo_black.svg" />}
          title="Marble Unlimited"
          subTitle="Elevate Your Space - Explore The World’s Most Inspiring Stones"
          initialValues={{
            autoLogin: true,
          }}
          onFinish={async (values) => {
            await handleSubmit(values);
          }}
          submitter={{
            searchConfig: {
              resetText: "Reset",
              submitText: "Login",
            },
          }}
        >
          <>
            <ProFormText
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fieldProps={{
                size: "large",
                prefix: <UserOutlined className={styles.prefixIcon} />,
              }}
              placeholder="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "email is required",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className={styles.prefixIcon} />,
              }}
              placeholder="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            />
          </>

          <div
            style={{
              marginBottom: 24,
            }}
          >
            <ProFormCheckbox noStyle name="autoLogin">
              Remember me
            </ProFormCheckbox>

            <a
              href="#forgot-password"
              style={{ float: "right" }}
              onClick={() => {
                forgotPassword();
              }}
            >
              Forgot Password ?
            </a>
          </div>
        </LoginForm>
      </div>
      <Footer />
      {redirect === "setup-account" && <AccountSetUp query={searchParams} />}
    </div>
  );
};

export default Login;
