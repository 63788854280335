import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { GridContent } from "@ant-design/pro-layout";
import { Menu, message } from "antd";
import BaseView from "./components/base";
import EmailView from "./components/notification";
import SyncView from "./components/syncView";
import SecurityView from "./components/security";
import marbleApi from "../../../services/http/marbleApi";
import { useParams } from "react-router-dom";
import "./style.less";

const { Item } = Menu;

const Settings = () => {
  const params = useParams();

  const { id, key } = params;

  const menuMap = {
    base: "Basic",
    security: "Security",
    email: "Email",
    sync: "Manual Sync",
  };
  const [initConfig, setInitConfig] = useState({
    mode: "inline",
    selectKey: key || "base",
  });
  const [user, setUser] = useState(null);
  const dom = useRef();

  useEffect(() => {
    async function getUser() {
      try {
        const response = await marbleApi.http.get(marbleApi.urls.user.get(id));
        if (response) {
          setUser(response);
        }
      } catch (error) {
        message.error(error?.message);
      }
    }
    getUser();
  }, [id]);

  useEffect(() => {
    setInitConfig({
      mode: "inline",
      selectKey: key || "base",
    })
  }, [key]);

  const resize = () => {
    requestAnimationFrame(() => {
      if (!dom.current) {
        return;
      }

      let mode = "inline";
      const { offsetWidth } = dom.current;

      if (dom.current.offsetWidth < 641 && offsetWidth > 400) {
        mode = "horizontal";
      }

      if (window.innerWidth < 768 && offsetWidth > 400) {
        mode = "horizontal";
      }

      setInitConfig({ ...initConfig, mode: mode });
    });
  };

  useLayoutEffect(() => {
    if (dom.current) {
      window.addEventListener("resize", resize);
      resize();
    }

    return () => {
      window.removeEventListener("resize", resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom.current]);

  const getMenu = () => {
    return Object.keys(menuMap).map((item) => (
      <Item key={item}>{menuMap[item]}</Item>
    ));
  };

  const renderChildren = () => {
    const { selectKey } = initConfig;

    switch (selectKey) {
      case "base":
        return <BaseView currentUser={user} />;

      case "security":
        return <SecurityView currentUser={user} />;

      case "email":
        return <EmailView currentUser={user} />;
      
      case "sync":
        return <SyncView currentUser={user} />;

      default:
        return null;
    }
  };

  return (
    <GridContent>
      <div
        className={"main"}
        ref={(ref) => {
          if (ref) {
            dom.current = ref;
          }
        }}
      >
        <div className={"leftMenu"}>
          <Menu
            mode={initConfig.mode}
            selectedKeys={[initConfig.selectKey]}
            onClick={({ key }) => {
              setInitConfig({ ...initConfig, selectKey: key });
            }}
          >
            {getMenu()}
          </Menu>
        </div>
        <div className={"rightmenu"}>
          <div className={"title"}>{menuMap[initConfig.selectKey]}</div>
          {user && renderChildren()}
        </div>
      </div>
    </GridContent>
  );
};

export default Settings;
