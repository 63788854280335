import Logs from "../../../components/Common/Logs";
import UserAvatar from "../../../components/Common/UserAvatar";
import {
  HistoryOutlined,
  MailOutlined,
  UserSwitchOutlined,
  GlobalOutlined
} from "@ant-design/icons";
import { GridContent } from "@ant-design/pro-layout";
import { Card, Col, Divider, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import marbleApi from "../../../services/http/marbleApi";
import { useParams } from "react-router-dom";

const operationTabList = [
  {
    key: "articles",
    tab: (
      <span>
        Logs{" "}
        <span
          style={{
            fontSize: 14,
          }}
        >

        </span>
      </span>
    ),
  },
];

const Center = (props) => {
  const [tabKey, setTabKey] = useState("articles");
  const params = useParams();

  const { id } = params;
  const [currentUser, setUser] = useState(null);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await marbleApi.http.get(marbleApi.urls.user.get(id));
        if (response) {
          setUser(response);
        }
      } catch (error) {
        message.error(error?.message);
      }
    }
    getUser();
  }, [id]);

  const renderUserInfo = ({ title, group, geographic }) => {
    return (
      <div className={"detail"}>
        <p>
          <MailOutlined
            style={{
              marginRight: 8,
            }}
          />
          {currentUser.email}
        </p>
        <p>
          <HistoryOutlined
            style={{
              marginRight: 8,
            }}
          />
          Active Since : {currentUser?.createdAt}
        </p>
        <p>
          <UserSwitchOutlined
            style={{
              marginRight: 8,
            }}
          />
          Account Status : {currentUser?.status}
        </p>
        <p>
          <GlobalOutlined
            style={{
              marginRight: 8,
            }}
          />
          Location : {currentUser?.location}
        </p>
      </div>
    );
  };

  const renderChildrenByTabKey = (tabValue) => {
    if (tabValue === "articles") {
      return <Logs userId={currentUser?.id} />;
    }

    return null;
  };

  return (
    <GridContent>
      <Row gutter={24}>
        <Col lg={7} md={24}>
          <Card
            bordered={false}
            style={{
              marginBottom: 24,
            }}
          >
            {currentUser && (
              <div>
                <div className={"avatarHolder"}>
                  <UserAvatar user={currentUser} size={224} round="10px" />
                  <div className={"name"}>{currentUser?.name}</div>
                  <div>{currentUser?.role}</div>
                </div>
                {renderUserInfo(currentUser)}
                <Divider
                  style={{
                    marginTop: 16,
                  }}
                  dashed
                />
                <div className={"team"}>
                  <div className={"teamTitle"}>Signature</div>
                  <img
                    style={{ width: "100%" }}
                    alt=""
                    src={currentUser?.signature}
                  />
                </div>
              </div>
            )}
          </Card>
        </Col>
        <Col lg={17} md={24}>
          <Card
            className={"tabsCard"}
            bordered={false}
            tabList={operationTabList}
            activeTabKey={tabKey}
            onTabChange={(_tabKey) => {
              setTabKey(_tabKey);
            }}
          >
            {renderChildrenByTabKey(tabKey)}
          </Card>
        </Col>
      </Row>
    </GridContent>
  );
};

export default Center;
