import { DefaultFooter } from "@ant-design/pro-layout";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <DefaultFooter
      copyright={`${currentYear} Marble Unlimited Inc.`}
      links={[]}
    />
  );
};

export default Footer;
