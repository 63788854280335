import {
  CheckCircleFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusCircleOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { getUser } from "../../../helpers";
import {
  ModalForm,
  ProForm,
  ProFormMoney,
  ProFormSelect,
} from "@ant-design/pro-components";
import {
  Alert,
  Button,
  Card,
  Collapse,
  message,
  Modal,
  Popconfirm,
  Space,
  Statistic,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import {
  invoiceCheck,
  paymentRemaining,
  paymentStatus,
} from "../../../helpers/order";
import marbleApi from "../../../services/http/marbleApi";
import ClientOrders from "./ClientOrders";
import "../style.less";

const { Panel } = Collapse;

function Payments({ order, syncOrder }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createModalVisible, handleModalVisible] = useState(false);
  const [form] = ProForm.useForm();
  const [currentAmount, setCurrentAmount] = useState(order.totalPrice);
  const [currentPayment, setCurrentPayment] = useState();
  const [selectedOrder, setSelectedOrder] = useState();

  useEffect(() => {
    listpayment();
  }, [order]);

  //adding payment
  const handleAdd = async (fields) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("adding payment");

      if (fields.method === "cash") {
        fields.status = "successful";
      } else {
        fields.status = "pending";
      }

      fields.orderId = order.id;

      try {
        await marbleApi.http.post(marbleApi.urls.payment.create, {
          data: fields,
        });
        hide();
        message.success("Payment Added");
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    };
  }

  //updating payment
  const handleUpdate = async (fields, id) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("updating payment");

      try {
        await marbleApi.http.post(marbleApi.urls.payment.update(id), {
          data: fields,
        });
        hide();
        message.success("payment updated successfully");
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    };
  }

  //moving payment
  function handleMove() {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      function check() {
        if (selectedOrder.length > 1) {
          message.error("please select just 1 order");
          return false;
        }
        if (selectedOrder[0].stage < 1) {
          message.error("Please generate Quote of selected order first");
          return false;
        }
        if (order.stage === 4) {
          const inv = invoiceCheck(order);
          if (inv < currentPayment.amount) {
            message.error("Payment already used in invoice");
            return false;
          } else {
            return true;
          }
        }
        if (selectedOrder[0].stage > 1) {
          const remaining = paymentRemaining(selectedOrder[0]);
          if (remaining < currentPayment.amount) {
            message.error(
              "Payment is more than the amount required for this order"
            );
            return false;
          } else {
            return true;
          }
        }
      }
      const checking = check();
      if (checking === true) {
        message.loading("Moving payment");
        handleUpdate({ orderId: selectedOrder[0].id }, currentPayment.id);
        setIsModalVisible(false);
      }
    }
  }

  const listpayment = async (
    params = {
      current: 1,
      pageSize: 1000,
    },
    options
  ) => {
    try {
      await marbleApi.http.post(marbleApi.urls.payment.list, {
        params,
        options,
      });
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  const columns1 = [
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: true,
      render: (dom, entity) => {
        return <strong>$ {dom}</strong>;
      },
    },
    {
      title: "Method",
      dataIndex: "method",
      valueType: "textarea",
      render: (dom, entity) => {
        return dom === "cash" ? "💵 Cash" : "💳 Card";
      },
    },

    {
      title: "status",
      dataIndex: "status",
      render: (dom, entity) => {
        if (dom === "successful") {
          return <Tag color="green">{dom}</Tag>;
        } else if (dom === "pending") {
          return <Tag color="blue">{dom}</Tag>;
        } else {
          return <Tag color="red">{dom}</Tag>;
        }
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      valueType: "textarea",
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      valueType: "textarea",
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      valueType: "option",
      render: (dom, entity) => [
        <Popconfirm
          title="Are you sure to archive this payment?"
          onConfirm={async (e, id = entity.id) => {
            await handleUpdate({ status: "archived" }, id);
            await listpayment();
          }}
          okText="Yes"
          cancelText="No"
        >
          {" "}
          <Button type="text" danger disabled={entity.status === "archived"}>
            <DeleteOutlined /> Archive
          </Button>
        </Popconfirm>,
        <Button
          onClick={() => {
            setCurrentPayment(entity);
            setIsModalVisible(true);
          }}
          type="text"
          disabled={entity.status === "archived" || entity.status === "pending"}
        >
          <SelectOutlined /> Move
        </Button>,
      ],
    },
  ];

  return (
    <>
      <Card
        title={
          <Space style={{display:"flex",flexWrap:"wrap"}}>
            <Card>
              <Statistic
                title="Payment Done"
                value={order?.stage === 5 ? 100 : paymentStatus(order)}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={<CheckCircleFilled />}
                suffix="%"
              />
            </Card>
            <Card>
              <Statistic
                title="Remaining"
                value={order?.stage === 5 ? 0 : paymentRemaining(order)}
                precision={2}
                valueStyle={{ color: "#cf1322" }}
                prefix="$"
              />
            </Card>
          </Space>
        }
        bordered={false}
        style={{}}
        extra={
          <>
            <Button
              type="link"
              onClick={() => {
                handleModalVisible(true);
              }}
            >
              <PlusCircleOutlined /> Add Payment
            </Button>
          </>
        }
        actions={[]}
      >
        <Collapse defaultActiveKey={["1"]} ghost>
          <Panel
            header={
              <span>
                <CheckCircleFilled style={{ color: "green" }} /> Valid Payments
              </span>
            }
            key="1"
          >
            <Table
              pagination={false}
              loading={false}
              dataSource={order.payments.filter(
                (payment) => payment.status !== "archived"
              )}
              columns={columns1}
            />
          </Panel>
          <Panel
            header={
              <span>
                <ExclamationCircleFilled style={{ color: "red" }} /> Archived
                Payments
              </span>
            }
            key="2"
          >
            <Table
              pagination={false}
              loading={false}
              dataSource={order.payments.filter(
                (payment) => payment.status === "archived"
              )}
              columns={columns1}
            />
          </Panel>
        </Collapse>
      </Card>
      <ModalForm
        title="🪙 Add new payment"
        width="60vw"
        visible={createModalVisible}
        onVisibleChange={handleModalVisible}
        form={form}
        submitter={{
          searchConfig: {
            submitText: "Create Payment",
          },
        }}
        onFinish={async (value) => {
          const success = await handleAdd(value);
          if (success) {
            handleModalVisible(false);
            syncOrder();
          }
        }}
      >
        <ProFormMoney
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={paymentRemaining(order)}
          label="Amount"
          name="amount"
          locale="en-US"
          placeholder={"Enter amount"}
          min={0}
          max={paymentRemaining(order)}
          onChange={(value) => setCurrentAmount(value)}
          addonAfter={
            <Alert
              type="info"
              message={
                ((currentAmount * 100) / order.totalPrice).toFixed(2) + "%"
              }
            ></Alert>
          }
        />

        <ProFormSelect
          name="method"
          label="Method"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[{ value: "cash", label: "Cash" }]}
        />
      </ModalForm>
      <Modal
        width={1000}
        style={{ height: "500px" }}
        opened={isModalVisible}
        okText="Move"
        onOk={() => handleMove()}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <ClientOrders
          order={order}
          visible={isModalVisible}
          setSelectedOrder={setSelectedOrder}
        />
      </Modal>
    </>
  );
}

export default Payments;
