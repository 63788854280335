import {
  ModalForm,
  ProFormSelect,
  ProFormText,
  ProFormMoney,
} from "@ant-design/pro-components";
import { useState, useEffect } from "react";
import { message} from "antd";
import { getUser } from "../../../helpers";
import marbleApi from "../../../services/http/marbleApi";



function AddAccount(props) {
  const { accountModalVisible, handleAccountModalVisible, linkAccout } = props
  const [industryList, setIndustryList] = useState([]);
    
  // add Account
    const addAccount = async (values) => {
        if (!getUser().zohoID) {
          message.error('You dont have an account on zoho')
        }
        else {
          values.owner = getUser().zohoID
          values.ownerName = getUser().name
            message.loading('...adding')
              try {
                const response = await marbleApi.http.post(
                  marbleApi.urls.zoho.create,
                  values
                );
                if (!response === false) {
                  linkAccout(response)
                  handleAccountModalVisible(false);
                  message.success("account added successfully")
                }
                else {
                  message.error("Account not added");
                }
              } catch (error) {
                message.error(error.message);
                return false;
              } 
        }
  };

    //get industries
    useEffect(() => {
      const getIndustries = async () => {
        try {
          const response = await marbleApi.http.post(marbleApi.urls.client.industries);
          if (response) {
            setIndustryList(response);
            return response;
          }
        } catch (error) {
          message.error(error?.message);
          return [];
        }
      };
      getIndustries();
    }, []);

  return (
    <>
      <ModalForm
        title="Add account"
        width="60vw"
        visible={accountModalVisible}
        onVisibleChange={handleAccountModalVisible}
        submitter={{
          searchConfig: {
            submitText: "Link Account",
          },
        }}
        onFinish={async (value) => {
          await addAccount(value);
        }}
      >
        <ProFormText
          rules={[
            {
              required: true,
            },
          ]}
          label="Account Name"
          name="name"
        />
        <ProFormSelect
          name="industry"
          label="Industry"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={industryList.map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
        />
          <ProFormSelect
          name="foreign"
          label="Foreign"
          initialValue="No"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[
            {
              label: "Yes",
              value: "Yes",
            },
            {
              label: "No",
              value: "No",
            },
          ]}
        />
        <ProFormMoney
          rules={[
            {
              required: true,
            },
          ]}
          label="Annual Revenue"
          initialValue={0}
          name="Annual_Revenue"
          locale="en-US"
          min={0}
        />
        <ProFormSelect
          name="Company_Size"
          label="Company Size"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[
            {
              label: "--None--",
              value: "null",
            },
            {
              label: "Small",
              value: "Small",
            },
            {
              label: "Medium",
              value: "Medium",
            },
            {
              label: "Big",
              value: "Big",
            },
          ]}
        />
        <ProFormText label="Website" name="website" />
        <ProFormText label="Phone" name="Phone" />
      </ModalForm>
    </>
  );
}

export default AddAccount