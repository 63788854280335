import React from "react";
import ZohoSync from "../../../../components/HeaderRightContent/ZohoSync";
import SapSync from "../../../../components/HeaderRightContent/SapSync";
import ShopifySync from "../../../../components/HeaderRightContent/ShopifySync";
import {getUser} from "../../../../helpers";

const SyncView = () => {
  return (
    <>
          {getUser().role !== "WAREHOUSE" && <ZohoSync />}
          <br />
          <br />
      {getUser().role !== "WAREHOUSE" && <SapSync user={getUser()} />}
      <br />
      <br />
      { getUser().role !== "WAREHOUSE" && <ShopifySync user={getUser()} /> }
    </>
  );
};

export default SyncView;
