import marbleApiService from "../../services/http/marbleApi";
import { PlusOutlined, StarFilled } from "@ant-design/icons";
import {Badge, message, Modal, Upload, Tooltip} from "antd";
import React, { useState, useEffect } from "react";

let tempImgIdStore = {};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function ImageUploadSlab(props) {
  const { callback, limit, images, slug, slab, block, } = props;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [count, setCount] = useState(0);
  const [fileList, setFileList] = useState(images);
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  useEffect(() => {
    setFileList(images)
    const shopify = images.filter(
      (i) => i.source === "SHOPIFY"
    )
      setCount(shopify.length)
  }, [images]);

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  //  uploading slab img
  const uploadFile = async (componentsData) => {
    const hide = message.loading("uploading...");
    let formData = new FormData();
    formData.append("file", componentsData.file);
    formData.append("slug", slug);
    formData.append("slabId", slab.id);

    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          componentsData.onProgress({ percent: progress });
        },
      };
      const response = await marbleApiService.http.post(
        marbleApiService.urls.asset.upload,
        formData,
        config
      );
      hide();

      message.success("uploaded successfully");
      tempImgIdStore[componentsData.file.uid] = response?.body.id;
      // -----if no hero img set img ----- //
      if (!block?.Product?.primaryImage) {
        await marbleApiService.http.post(marbleApiService.urls.products.updateProduct, {
          id: block?.productId,
          data: { primaryImage: response?.body?.url},
        });
      }
      componentsData.onSuccess();
      callback(response)
    } catch (error) {
      hide();
      componentsData.onError("Error uploading image");
      message.error(error.message);
    }
  };

  //  ------uploading to shopify-------  //
  const uploadShopify = async (componentsData) => {
    if (block.shopifyProductId) {
      if (componentsData.source !== "SHOPIFY") {
        const hide = message.loading("Uploading to shopify...");
        const response = await marbleApiService.http.post(
          marbleApiService.urls.asset.uploadSlabShopify,
          {
            product_id: block?.shopifyProductId,
            variant_id: block?.shopifyBlockId,
            blockId: block?.id,
            url: componentsData.url,
            name: componentsData.name,
            id: componentsData.prefix,
            block,
          }
        );
        hide();
        callback(response)
        //  -- setting img as hero -- //
        if (response) {
            await marbleApiService.http.post(marbleApiService.urls.products.updateProduct, {
            id: block?.productId,
            data: { primaryImage: componentsData.url},
          });
        }
        message.success("Uploaded successfully");
      } else {
        const hide = message.loading("removing...");
        const response =  marbleApiService.http
          .post(marbleApiService.urls.asset.removeShopify, {
            product_id: block?.shopifyProductId,
            image_id: componentsData.prefix,
            source: componentsData.source,
          })
          .then((data) => {
            callback(response)
            hide();
            message.success("removed successfully");
          })
          .catch((error) => {
            message.error(error.message);
          });
      }
    } else {
      message.info("Material not on Shopify");
    }
  };

  //  --- remove picture ---  //
  const removeFile = (file) => {
    if (file.source === "SHOPIFY") {
      message.error("First remove from shopify");
      callback(file)
    } else {
      const hide = message.loading("removing...");
      if (file.prefix || tempImgIdStore[file.uid]) {
        const response = marbleApiService.http
          .post(marbleApiService.urls.asset.remove, {
            id: file.prefix || tempImgIdStore[file.uid],
            url: file.url
          })
          .then((data) => {
            callback(response)
            hide();
            message.success("removed successfully");
            return true;
          })
          .catch((error) => {
            message.error(error.message);
            return true;
          });
      } else {
        message.error("Couldn't remove. Please reload page and try again.");
        return false;
      }
    }
  };

  return (
    <>
      <Badge count={count} style={{ backgroundColor: '#2F54EB' }}>
      <Upload
        customRequest={uploadFile}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={removeFile}
        onChange={handleChange}
        onDownload={uploadShopify}
        className="image-upload"
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: (record) => (
            <Tooltip
              title={
                record.source === "SHOPIFY"
                  ? "Remove from shopify"
                  : "Upload to shopify"
              }
            >
              
              <StarFilled
                style={{
                  color: record.source === "SHOPIFY" ? "yellow" : "white",
                  fontSize: "100px"
                }}
              />
            </Tooltip>
          ),
        }}
      >
        {fileList.length >= limit ? null : uploadButton}
      </Upload>
      </Badge>
       

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width={"60%"}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
