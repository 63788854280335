import { CarryOutTwoTone, EnvironmentTwoTone } from "@ant-design/icons";
import { Button, Collapse, DatePicker, Form, Input } from "antd";

const { Panel } = Collapse;

function Address({ setDelivery, setPickup }) {

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = (values) => {
    setDelivery(values);
  };

  const onPickup = (values) => {
    setPickup(values);
  };

  return (
    <Collapse defaultActiveKey={[]} ghost>
      <Panel
        header={
          <span>
            <EnvironmentTwoTone /> Delivery Details
          </span>
        }
        key="1"
      >
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true }]}
            
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Panel>
      <Panel
        header={
          <span>
            <CarryOutTwoTone /> Pick-Up Details
          </span>
        }
        key="2"
      >
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onPickup}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true }]}
           
            
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item
            name="company"
            label="Pickup Company"
            rules={[{ required: true }]}
            
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="person"
            label="Contact Person"
            rules={[{ required: true }]}
            
          >
            <Input  />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Panel>
    </Collapse>
  );
}

export default Address;
