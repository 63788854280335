import {
  CheckCircleFilled,
  CopyOutlined,
  DeleteOutlined,
  EditTwoTone,
  ExclamationCircleFilled,
  PlusOutlined,
  SelectOutlined,
  ShoppingTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  message,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import { useState } from "react";
import Signature from "../../../components/Common/Signature";
import marbleApi from "../../../services/http/marbleApi";
import Address from "./Address";
import { getUser } from "../../../helpers";
import ProductsInvoice from "./ProductsInvoice";

const { Panel } = Collapse;

function Invoices(props) {
  const { order, syncOrder } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState({});
  const [signUrl, setSignUrl] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [pickup, setPickup] = useState(null);
  const [proInv, setProInv] = useState(null);
  const [total, setTotal] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const signatureCallback = (action, data) => {
    if (action === "upload") setSignUrl(data.url);
  };

  //creating Invoice
  async function handleCreate() {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      if (!delivery || !pickup) {
        message.error("please fill and save dilevery & pickup fields");
      }
      if (!proInv) {
        message.error("No products selected");
      }
      if (!signUrl) {
        message.error("Pickup Person Signature Required!!! ");
      } else {
        message.loading("creating");
        try {
          await marbleApi.http.post(marbleApi.urls.invoice.create, {
            order_id: order?.id,
            data: { slabs: proInv, delivery, pickup, pickUpSign: signUrl, total },
          });
          message.success("invoice created successfully");
          setModalVisible(false);
          await syncOrder();
          return true;

        } catch (error) {
          message.error(error);
          return false;
        }
      }
    }
  }

  //archieving invoice
  const handleUpdate = async (fields, id) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("Archieving payment");
      try {
        await marbleApi.http.post(marbleApi.urls.invoice.update(id), {
          data: fields,
        });
        hide();
        message.success("Invoice Archieved");
        await syncOrder();
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    };
  }

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "id",
      sorter: true,
      render: (dom, entity) => {
        return (
          <h4
            onClick={() => {
              setIsModalVisible(true)
              setCurrentInvoice(entity);
            }}
          >
            {" "}
            {dom}
          </h4>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "textarea",
    },

    {
      title: "",
      dataIndex: "viewed_at",
      valueType: "textarea",
      render: (dom, entity) => [
        <Tooltip title="Copy Quote PDF Link" key={1}>
          <Button
            type="link"
            onClick={() => {
              navigator.clipboard.writeText(entity?.pdf.url);
              message.success("Copied to clipboard");
            }}
          >
            <CopyOutlined /> Copy Url
          </Button>
        </Tooltip>,
        <Button
          type="link"
          key={3}
          onClick={() => {
            window.open(entity?.pdf.url, "_blank");
          }}
        >
          <SelectOutlined /> Open
        </Button>,
      ],
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      valueType: "option",
      render: (dom, entity) => [
        <Popconfirm
          title="Are you sure to archive this payment?"
          onConfirm={async (e, id = entity.id) => {
            await handleUpdate({ status: "archived" }, id);
          }}
          okText="Yes"
          cancelText="No"
        >
          {" "}
          <Button type="text" danger disabled={entity.status === "archived"}>
            <DeleteOutlined /> Archive
          </Button>
        </Popconfirm>,
      ],
    },
  ];

  return (
    <>
      {" "}
      <Card
        bordered={false}
        style={{}}
        extra={
          <>
            <Button
              type="link"
              style={{ float: "right", marginBottom: 20 }}
              onClick={() => setModalVisible(true)}
            >
              <PlusOutlined /> Create Invoice
            </Button>
          </>
        }
        actions={[]}
      >
        <Collapse defaultActiveKey={["1"]} ghost>
          <Panel
            header={
              <span>
                <CheckCircleFilled style={{ color: "green" }} /> Valid Invoice
              </span>
            }
            key="1"
          >
            <Table
              pagination={false}
              loading={false}
              dataSource={order?.Invoice.filter(
                (Invoice) => Invoice.status === "valid"
              )}
              columns={columns}
            />
          </Panel>
          <Panel
            header={
              <span>
                <ExclamationCircleFilled style={{ color: "red" }} /> Archived
                Invoice
              </span>
            }
            key="2"
          >
            <Table
              pagination={false}
              loading={false}
              dataSource={order?.Invoice.filter(
                (Invoice) => Invoice.status === "archived"
              )}
              columns={columns}
            />
          </Panel>
        </Collapse>
      </Card>
      <Modal
        title="Create Invoice"
        centered
        maskClosable={false}
        opened={modalVisible}
        footer={[
          <Button disabled={total? false: true} key="submit" type="primary" onClick={handleCreate}>
            Create Invoice
          </Button>
        ]}
        onCancel={() => setModalVisible(false)}
        width="65%"
        style={{}}
      >
        <Address setDelivery={setDelivery} setPickup={setPickup} />
        <Collapse defaultActiveKey={[]} ghost>
          <Panel
            header={
              <span>
                <ShoppingTwoTone /> Products
              </span>
            }
            key="1"
          >
            <ProductsInvoice
              order={order}
              setTotal={setTotal}
              setProInv={setProInv}
            />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={[]} ghost>
          <Panel
            header={
              <span>
                <EditTwoTone /> Pick up person Signature
              </span>
            }
            key="1"
          >
            {signUrl ? (
              <img
                className="signature_img"
                style={{ width: "100px" }}
                alt="signature"
                src={signUrl}
              />
            ) : (
              <Button
                onClick={() => {
                  setSignatureModal(true);
                }}
              >
                Add Signature
              </Button>
            )}
          </Panel>
        </Collapse>
      </Modal>
      {signatureModal && (
        <Signature
          callback={signatureCallback}
          setVisible={setSignatureModal}
        />
      )}
      <Modal title={`Invoice Details `} opened={isModalVisible} footer={null} onCancel={() => { setIsModalVisible(false) }}>
        
        <h5>Delivery Address:</h5>
        <p>{currentInvoice?.delivery?.address}</p>
        <h5>Delivery Date:</h5>
        <p>{currentInvoice?.delivery?.date}</p>
        <h5>Pickup Person Address:</h5>
        <p>{currentInvoice?.pickup?.address}</p>
        <h5>Pickup Date:</h5>
        <p>{currentInvoice?.pickup?.date}</p>
        <h5>Pickup Person's Company:</h5>
        <p>{currentInvoice?.pickup?.company || "N/A"}</p>
        <h5>Pick up Person's name</h5>
        <p>{currentInvoice?.pickup?.person}</p>
        <h5>Status:</h5>
        <p>{currentInvoice?.status}</p>
        <h5>Invoice Total: </h5>
        <p>${currentInvoice?.total}</p>
        <h5>Pickup Person's Signature: </h5>
        <img alt="example" style={{ width: '20%' }} src={currentInvoice?.pickUpSign} />

      </Modal>
    </>
  );
}

export default Invoices;
