import { Avatar, List, Button, Row, Col, Empty } from "antd";
import React from "react";
import classNames from "classnames";
import { ClearOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./NoticeList.less";

const NoticeList = ({
  list = [],
  onClick,
  onClear,
  title,
  onViewMore,
  emptyText,
  showClear = true,
  clearText,
  viewMoreText,
  showViewMore = false,
}) => {
  if (!list || list.length === 0) {
    return (
      <div className="notFound">
        <Empty />
        <div>{emptyText}</div>
      </div>
    );
  }

  return (
    <div>
      <List
        className="list"
        dataSource={list}
        renderItem={(item, i) => {
          const itemCls = classNames("item", {
            "read": item.read,
          });

          const leftIcon = item.avatar ? (
            typeof item.avatar === "string" ? (
              <Avatar className="avatar" src={item.avatar} />
            ) : (
              <span className="iconElement">{item.avatar}</span>
            )
          ) : null;
          return (
            <List.Item
              className={itemCls}
              key={item.key || i}
              onClick={() => {
                onClick?.(item);
              }}
            >
              <List.Item.Meta
                className="meta"
                avatar={leftIcon}
                title={
                  <div className="title">
                    {item.title}
                    <div className="extra">{item.extra}</div>
                  </div>
                }
                description={
                  <div>
                    <div className="description">{item.description}</div>
                    <div className="datetime">{item.datetime}</div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <div className="bottomBar">
        <Row>
          <Col span={12}>
            {" "}
            <Button onClick={onClear} type="link" danger block>
              <ClearOutlined /> {clearText} {title}
            </Button>
          </Col>
          <Col span={12}>
            {" "}
            <Button
              type="link"
              onClick={(e) => {
                if (onViewMore) {
                  onViewMore(e);
                }
              }}
              block
            >
              <PlusCircleOutlined /> {viewMoreText}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NoticeList;
