import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ProtectedRoute from "./components/Layout/ProtectedRoute";
import VerifyAccess from "./components/Layout/VerifyAccess";
import reportWebVitals from "./reportWebVitals";

/* Pages */

import Center from "./pages/account/center";
import Settings from "./pages/account/settings";
import Client from "./pages/client";
import Dashboard from "./pages/dashboard/index";
import DeniedMaterial from "./pages/deniedMaterial";
import Order from "./pages/order";
import NewOrder from "./pages/order/new";
import Orders from "./pages/orders";
import Login from "./pages/user/Login";
import Users from "./pages/users";
import Images from "./pages/images";
import Products from "./pages/products";
import NoFoundPage from "./pages/404";
import GlobalState from "./context/GlobalState";
import PriceDashboard from "./pages/price/PriceDashboard";
import NewArrival from "./pages/newArrivals";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalState>
    <HashRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<ProtectedRoute />}>
          <Route
            path="/"
            element={
              <App>
                <Dashboard />
              </App>
            }
          />
          {/* Home */}
          <Route
            path="home"
            element={
              <App>
                <Dashboard />
              </App>
            }
          />

          {/* Users */}
          <Route
            path="users"
            element={
              <VerifyAccess access={[]}>
                {" "}
                <App>
                  <Users />
                </App>
              </VerifyAccess>
            }
          />

          {/* Orders */}
          <Route
            path="orders"
            element={
              <VerifyAccess access={["SALES","ACCOUNTING"]}>
                {" "}
                <App>
                  <Orders />
                </App>
              </VerifyAccess>
            }
          />

          <Route
            path="orders/:id"
            element={
              <VerifyAccess access={["SALES","ACCOUNTING"]}>
                {" "}
                <App>
                  <Order />
                </App>
              </VerifyAccess>
            }
          />

          <Route
            path="orders/create-new-order"
            element={
              <VerifyAccess access={["SALES"]}>
                <App>
                  <NewOrder />
                </App>
              </VerifyAccess>
            }
          />

          {/* Products */}
          <Route
            path="products"
            element={
              <VerifyAccess access={["WAREHOUSE","STONECLUB"]}>
                {" "}
                <App>
                  <Products />
                </App>
              </VerifyAccess>
            }
          />
          <Route
            path="products/:id"
            element={
              <VerifyAccess access={["WAREHOUSE","STONECLUB"]}>
                {" "}
                <App>
                  <Products />
                </App>
              </VerifyAccess>
            }
          />

          {/* Images */}
          <Route
            path="images"
            element={
              <VerifyAccess access={[]}>
                {" "}
                <App>
                  <Images />
                </App>
              </VerifyAccess>
            }
          />

          {/* Account */}

          <Route
            path="account/center/:id"
            element={
              <App>
                <Center />
              </App>
            }
          />

          <Route
            path="account/settings/:id/:key"
            element={
              <App>
                <Settings />
              </App>
            }
          />
        </Route>

        {/* Client */}
        <Route
          path="clients"
          element={
            <VerifyAccess access={["ACCOUNTING"]}>
              {" "}
              <App>
                <Client />
              </App>
            </VerifyAccess>
          }
        />

        {/* Denied Matarial */}
        <Route
          path="/denied-materials"
          element={
            <VerifyAccess access={[]}>
              {" "}
              <App>
                <DeniedMaterial />
              </App>
            </VerifyAccess>
          }
        />
        {/* New Arrival */}
        <Route
          path="/new-arrivals"
          element={
            <VerifyAccess access={["STONECLUB","VENDOR"]}>
              {" "}
              <App>
                <NewArrival />
              </App>
            </VerifyAccess>
          }
        />
        {/* Price Dashboard */}
        <Route
          path="/price"
          element={
            <VerifyAccess access={[]}>
              {" "}
              <App>
                <PriceDashboard />
              </App>
            </VerifyAccess>
          }
        />

        <Route
          path="*"
          element={
            <App>
              <NoFoundPage />
            </App>
          }
        />
      </Routes>
    </HashRouter>
  </GlobalState>
);

reportWebVitals();
