import { getFromLocalStorage,getUser } from "../../helpers";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../Common/UserAvatar";
import HeaderDropdown from "../HeaderDropdown";

const currentUser = getFromLocalStorage("user");

const AvatarDropdown = ({ menu }) => {
  const navigate = useNavigate();

  const loginOut = async () => {
    localStorage.clear();
    navigate("/login");
  };

  const onMenuClick = ({key}) => {
    if (key === undefined) {
      return;
    } else if (key === "logout") {
      loginOut();
      return;
    } else if (key === "center") {
      navigate(`/account/${key}/${currentUser?.id}/`);
    } else {
      navigate(`/account/settings/${currentUser?.id}/${key}`);
    }
  };

  const menuHeaderDropdown = (
    <Menu className="menu" selectedKeys={[]} onClick={onMenuClick}>
      {menu && (
        <Menu.Item key="center">
          <UserOutlined />
          Account Info
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="base">
          <SettingOutlined />
          Acount Settings
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="sync">
        <SyncOutlined />
         Manual Sync
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="logout">
        <LogoutOutlined />
        Log Out
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className="action account">
        <UserAvatar user={getUser()} size="36" round="15px" />
        <strong style={{ margin: 15, color: "#FFF" }}>
          {getUser()?.name}
        </strong>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
