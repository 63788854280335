import React, { useEffect, useState } from "react";
import Stats from "./components/stats";
import { getUser } from "../../helpers";
import marbleApi from "../../services/http/marbleApi";
import QuoteTable from "./components/table";
import { message } from "antd";
import "./style.less";

const Dashboard = () => {
  const [data, setData] = useState(null);

  //  get dashboard data
  useEffect(() => {
    async function getData() {
      let params = null;
      if (getUser().role !== "ADMIN") {
        params = {
          id: getUser().id,
          name: getUser().name,
        };
      }
      try {
        const response = await marbleApi.http.post(
          marbleApi.urls.dashboard.list,
          params
        );
        if (response) {
          setData(response);
        }
      } catch (error) {
        message.error(error?.message);
      }
    }
    getData();
  }, []);
  return (
    <>
      <Stats data={data} />
      <QuoteTable data={data} />
    </>
  );
};

export default Dashboard;
