import ImageUpload from "../../../../components/Common/ImageUpload";
import Signature from "../../../../components/Common/Signature";
import { EditTwoTone } from "@ant-design/icons";
import ProForm, { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Alert, Button, message } from "antd";
import React, { useState } from "react";
import marbleApi from "../../../../services/http/marbleApi";
import {getUser} from "../../../../helpers";

const BaseView = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { currentUser } = props;
  const [form] = ProForm.useForm();

  const profileCallback = (action, data) => {
    if (action === "upload") form.setFieldsValue({ avatar: data.thumbUrl });
    if (action === "delete") form.setFieldsValue({ avatar: "" });
  };

  const signatureCallback = (action, data) => {
    if (action === "upload") form.setFieldsValue({ signature: data.url });
    if (action === "delete") form.setFieldsValue({ signature: "" });
  };

  const AvatarView = () => (
    <>
      <div className={"avatar_title"}>Profile Picture</div>
      <ImageUpload
        callback={profileCallback}
        limit={1}
        images={
          currentUser?.avatar
            ? [
              {
                uid: "na",
                name: currentUser.name,
                status: "done",
                url: currentUser.avatar,
              },
            ]
            : []
        }
        slug="profile"
      />
    </>
  );

  const SignatureView = () => (
    <>
      <div className={"avatar_title"}>Signature</div>
      <ImageUpload
        callback={signatureCallback}
        limit={1}
        images={
          currentUser?.signature
            ? [
              {
                uid: "na",
                name: currentUser.name,
                status: "done",
                url: currentUser.signature,
              },
            ]
            : []
        }
        slug="signature"
      />
      <Button type="link" onClick={() => setModalVisible(true)}>
        <EditTwoTone />
        Create New Signature
      </Button>
    </>
  );

  const handleFinish = async (values) => {
    const hide = message.loading("Updating");

    try {
      await marbleApi.http.post(marbleApi.urls.user.update(currentUser?.id), {
        data: values,
      });
      hide();
      message.success("Updated successsfully");
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };

  return (
    <>
      <div className={"baseView"}>
        <>
          <div className={"base-left"}>
            <ProForm
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              submitter={{
                searchConfig: {
                  submitText: "Save",
                },
                resetButtonProps: {
                  style: {
                    display: "none",
                  },
                },
                submitButtonProps: {
                  children: "Submit",
                },
              }}
              initialValues={{ ...currentUser }}
              hideRequiredMark
            >
              <ProFormText
                width="md"
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              />

              <ProFormSelect
                width="sm"
                disabled= {getUser().role !== "ADMIN"}
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
                options={[
                  {
                    label: "Admin",
                    value: "ADMIN",
                  },
                  {
                    label: "Sales",
                    value: "SALES",
                  },
                  {
                    label: "Warehouse",
                    value: "WAREHOUSE",
                  },
                  {
                    label: "Accounting",
                    value: "ACCOUNTING",
                  },
                  {
                    label: "stoneclub",
                    value: "STONECLUB",
                  },
                ]}
              />
              <ProFormSelect
                width="sm"
                name="location"
                label="Location"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
                options={[
                  {
                    label: "Van Nuys",
                    value: "Van Nuys",
                  },
                  {
                    label: "Anaheim",
                    value: "Anaheim",
                  },
                ]}
              />
              <ProFormText name="avatar" hidden />
              <ProFormText name="signature" hidden />
            </ProForm>
          </div>
          <div className={"base-right"}>
            <AvatarView />
          </div>
          <div className={"base-right"}>
            <SignatureView />
          </div>
        </>
        {modalVisible && (
          <Signature
            callback={signatureCallback}
            setVisible={setModalVisible}
          />
        )}
      </div>

      <div
        style={{
          marginTop: "1em",
        }}
      >
        <Alert
          type="warning"
          banner
          message={
            <i>
              Please click on save button after changing profile picture and
              signtaure.
            </i>
          }
          showIcon={false}
        />
      </div>
    </>
  );
};

export default BaseView;
