import { Navigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers";
import NoAccess from "../../pages/NoAccess";

const VerifyAccess = ({ children, access }) => {
  const user = getFromLocalStorage("user");

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!access.includes(user.role) && !user.role === "ADMIN") {
    return <NoAccess />;
  }

  return children;
};

export default VerifyAccess;
