import { SelectOutlined } from "@ant-design/icons";
import {
  ProForm,
  ModalForm,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormSwitch,
  ProFormDatePicker,
} from "@ant-design/pro-components";
import { Divider, Alert, Button, message } from "antd";
import { useRef, useState, useEffect } from "react";
import { getUser } from "../../../helpers";
import marbleApi from "../../../services/http/marbleApi";
import ImageUpload from "../../../components/Common/ImageUpload";
import LinkAccount from "./LinkAccount";



function AddClient(props) {
  const { handleModalVisible, createModalVisible, mode, currentRow, setupdated } = props
  const [salesReps, setSalesReps] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [url, setUrl] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [salesPerson, setSalesPerson] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [zohoAccount, setZohoAccount] = useState(null);
  const [showAccountJSX, setShowAccountJSX] = useState(false);
 
  const signatureCallback = (action, data) => {
    if (action === "upload") setUrl(data.url);
    if (action === "delete") setUrl(null);
  };
  const actionRef = useRef();
  const [form] = ProForm.useForm();

  useEffect(() => {
    form.setFieldsValue(currentRow);
  }, [form, currentRow]);

  const handleAdd = async (fields) => {
    try {

        const hide = message.loading("adding client");
        fields.source = "APP";
        fields.zohoAccountId = zohoAccount;
        fields.zohoOwnerId = null;
        fields.status = "ACTIVE";
        fields.shipping_address_text = fields.address;
        fields.billing_address_text = fields.address;
        if (fields.resellDueDate) {
          fields.resellDueDate = new Date(fields.resellDueDate).toISOString();
        }
        fields.resellPDF = url
  
        delete fields.address;
  
        const response = await marbleApi.http.post(marbleApi.urls.client.create, { data: fields });
      if (response) {
        if (response==='DuplicateEmail') {
          message.error("A contact with same Email already exist");
        }
        else if (response==='DuplicateName') {
          message.error("A contact with same Name already exist");
        }
        else {
          hide();
          message.success("Contact Added successfully");
          setupdated(fields)
          return true;
        }
        }
    } catch (error) {
      message.error(error.message);
      return false;
    }
  };

  const handleUpdate = async (fields, id) => {
    const hide = message.loading("updating client");

    try {
      if (url) {
        fields.resellPDF = url;
      }
      if (fields.resellDueDate) {
        fields.resellDueDate = new Date(fields.resellDueDate).toISOString();
      }

      await marbleApi.http.post(marbleApi.urls.client.update(id), {
        data: fields,
      });
      hide();
      message.success("Contact updated successfully");
      setupdated(fields)
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };


  //get sales person
  useEffect(() => {
    const getSalesReps = async (params, options) => {
      try {
        const response = await marbleApi.http.post(marbleApi.urls.user.list, {
          params: { current: 1, pageSize: 1000 },
          options,
        });
        if (response) {
          setSalesReps(response?.data?.filter((i) => (i.role === 'SALES' || i.role === 'ADMIN') && i.name));
          // setSalesReps(response.data);
          return response;
        }
      } catch (error) {
        message.error(error?.message);
        return [];
      }
    };
    getSalesReps();
  }, []);

    //get industries
    useEffect(() => {
      const getIndustries = async () => {
        try {
          const response = await marbleApi.http.post(marbleApi.urls.client.industries);
          if (response) {
            setIndustryList(response);
            return response;
          }
        } catch (error) {
          message.error(error?.message);
          return [];
        }
      };
      getIndustries();
    }, []);

  return (
    <>
      <ModalForm
        title={mode === "add" ? "🤵‍♂️ Create New Contact" : "Edit Contact"}
        width="60vw"
        visible={createModalVisible}
        onVisibleChange={handleModalVisible}
        initialValues={currentRow}
        form={form}
        submitter={{
          searchConfig: {
            submitText: "Create",
          },
          submitButtonProps: {
              disabled: salesPerson?.zohoID && !zohoAccount,
            },
        }}
        onFinish={async (value) => {
          let success = null;
          if (mode === "add") {
            success = await handleAdd(value);
          }
          if (mode === "edit") {
            success = await handleUpdate(value, currentRow.id);
          }

          if (success) {
            handleModalVisible(false);

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
      >

        <ProFormText
          rules={[
            {
              required: true,
              message: "Name",
              type: "text",
            },
          ]}
          label="Name"
          name="name"
          placeholder={"Enter name"}
          onChange={(e) => setClientName(e.target.value)}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        <ProFormText
          rules={[
            {
              type: "email",
              required: true,
              message: "Required!",
            },
          ]}
          label="Email"
          name="email"
          placeholder={"Enter email"}
          onChange={(e) => setClientEmail(e.target.value)}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        <ProFormText
          label="Phone"
          name="phone"
          placeholder={"Enter phone"}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        <ProFormSelect
          name="group"
          label="Industry"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={industryList.map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        <ProFormTextArea
          rules={[
            {
              type: "text",
            },
          ]}
          label="Billing Address"
          name="billing_address_text"
          placeholder={"Enter billing address"}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        <ProFormTextArea
          rules={[
            {
              type: "text",
            },
          ]}
          label="Shipping Address"
          name="shipping_address_text"
          placeholder={"Enter shipping address"}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />

        <ProFormSelect
          name="sales_person"
          label="Sales Rep. incharge"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={salesReps.map((item) => {
            return {
              value: item.name,
              label: item.name,
            };
          })}
          onChange={(selected) => {
            let salesUser = salesReps.find((item) => item.name === selected);
            if (salesUser) {
              setSalesPerson(salesUser);
            }
          }}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />

        <ProFormText
          rules={[
            {
              type: "text",
            },
          ]}
          label="Referral"
          name="referral"
          placeholder="Please enter "
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        {zohoAccount ? (
          <Alert type="info" message="Account Linked" />
        ) : ( 
          <>
             {mode === "add" && <Button
                disabled={!clientEmail || !clientName || !salesPerson?.zohoID}
                type="primary"
                onClick={() => setShowAccountJSX((value) => !value)}
              >
                {" "}
                Account Information
              </Button>}
            {showAccountJSX === true && mode === "add" && (
              <LinkAccount
                name={clientName}
                email={clientEmail}
                setZohoAccount={setZohoAccount}
              />
            )}
          </>
        )}
        <Divider />
        <h3>Resell Form</h3>
        {currentRow?.resellPDF && <Button
          type="link"
          onClick={() => {
            window.open(currentRow?.resellPDF, "_blank");
          }}
        >
          <SelectOutlined /> Resell pdf
        </Button>}


        {getUser().role !== "ADMIN" && mode === "edit" ? null :
          <ImageUpload
            callback={signatureCallback}
            limit={1}
            images={[]
            }
            slug="resellForm"
          />}

        <ProFormDatePicker name="resellDueDate" label="Due Date"
          rules={[
            {
              required: url ? true : false,
              message: "Required!",
            },
          ]}
          disabled={getUser().role !== "ADMIN" && mode === "edit"}
        />
        <ProFormSwitch disabled={getUser().role !== "ACCOUNTING"} name="resellValid" label="Valid" />


      </ModalForm>
    </>
  )
}

export default AddClient