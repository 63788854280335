import ImageUploadShopify from "../../components/Common/ImageUploadShopify";
import {
  DeleteOutlined,
  EllipsisOutlined,
  SyncOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable from "@ant-design/pro-table";
import { debounce } from "../../helpers";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Radio,
  Space,
  Tabs,
  ConfigProvider,
  Tooltip,
} from "antd";
import { useRef, useState,useEffect } from "react";
import marbleApi from "../../services/http/marbleApi";
import enUSIntl from "antd/lib/locale/en_US";

const { TabPane } = Tabs;


const Images = () => {
  const actionRef = useRef();

  const [previewVisible, setpreviewVisible] = useState(false);
  const [nested, setNested] = useState({});

  const handleCancel = () => setpreviewVisible(false);

  useEffect(() => {
    debounce(() => actionRef.current.reload())();
  }, [nested]);

  //  filter
  const updateNested = (key, value) => {
    setNested((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const listBlocks = async (params, options) => {
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.products.listBlocks,
        {
          params,
          options,
          nested,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      tip: "Material Name",
      render: (dom, entity) => {
        return (
          <Tooltip title="View on Shopify">
            <a
              href={`https://marbleunlimitedinc.com/collections/materials/products/${entity?.shopifyHandle}`}
              target="_blank"
              rel="noreferrer"
            >
              <h4> {dom} <LinkOutlined style={{color:'#2F54EB'}} /></h4>
            </a>
          </Tooltip>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      valueType: "textarea",
    },
    {
      title: "Block",
      dataIndex: "blockId",
      valueType: "textarea",
    },

    {
      title: "Images",
      dataIndex: "images",
      sorter: true,
      search: false,
      hideInForm: true,
      render: (dom, entity) =>
        entity.shopifyProductId ? (
          <ImageUploadShopify
            limit={20}
            images={entity.images.map((image) => ({
              uid: image.id,
              prefix: image.prefix,
              name: image.name,
              status: "done",
              url: image.url,
            }))}
            slug="shopify"
            block={entity}
          />
        ) : (
          <>⚠ Not on Shopify</>
        ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      valueType: "textarea",
      sorter: true,
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <SyncOutlined /> Sync Products From SAP
      </Menu.Item>
    </Menu>
  );

  return (
    <ConfigProvider locale={enUSIntl}>
      <PageContainer title="StonePro">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Manual Upload" key="1">
            <ProTable
              headerTitle="Images"
              actionRef={actionRef}
              rowKey="key"
              search={{
                labelWidth: 140,
              }}
              options={{
                fullScreen: false,
                reload: true,
                density: false,
                setting: false,
              }}
              toolBarRender={() => [
                <Radio.Group defaultValue={null}
                  className='order_radio'
                  buttonStyle="solid"
                  onChange={(e) => updateNested("images_length", e.target.value)}
                >
                  <Radio.Button value={null}>All</Radio.Button>
                  <Radio.Button value="SHOPIFY" >Product With Images</Radio.Button>
                  <Radio.Button value="not">Product Without Images</Radio.Button>
                </Radio.Group>,
                <Dropdown key="menu" overlay={menu}>
                  <Button type="link">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>,
              ]}
              request={listBlocks}
              columns={columns}
              tableAlertRender={({
                selectedRowKeys,
                selectedRows,
                onCleanSelected,
              }) => (
                <Space size={24}>
                  <span>
                    {selectedRowKeys.length} products selected
                    <button type="button" style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                      Clear Selection
                    </button>
                  </span>
                </Space>
              )}
              tableAlertOptionRender={() => {
                return (
                  <Space size={16}>
                    <Button key={1} type="link" danger>
                      <DeleteOutlined /> Delete Images
                    </Button>
                  </Space>
                );
              }}
            />
          </TabPane>
        </Tabs>

        <Modal
          opened={previewVisible}
          title={""}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={""} />
        </Modal>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Images;
