import {
  Image,
  message,
  Badge,
  Tooltip,
  Button,
  Spin,
  Collapse,
  Tag,
} from "antd";
import {
  ModalForm,
  ProFormDigit,
} from "@ant-design/pro-form";
import { getUser } from "../../../helpers";
import React, { useEffect, useState } from "react";
import Slabs from "./Slabs";
import {
  LinkOutlined,
  ReloadOutlined,
  DollarOutlined,
  EditOutlined
} from "@ant-design/icons";
import marbleApi from "../../../services/http/marbleApi";
import { warehouses } from "../../../constants";
import ProductEdit from "./ProductEdit";
const stc = require("string-to-color");
const { Panel } = Collapse;

export default function ProductView(props) {
  const { id, setTitle, nested } = props;
  const [product, setProduct] = useState({ blocks: [] });
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productEditPreview, setProductEditPreview] = useState(false);
  const [blockEditPreview, setBlockEditPreview] = useState(false);
  const [blockId, setBlockId] = useState(null);

  const { callback } = props;

  useEffect(() => {
    getProduct(id);
  }, [id]);

  const handleUpdate = async (fields) => { 
    const hide = message.loading("Updating");
    try {
      await marbleApi.http.post(marbleApi.urls.products.updateBlock, {
        id: blockId,
        data: fields,
      });
      hide();
      message.success("Updated successsfully");
      getProduct(id);
      setBlockEditPreview(false);
    }
    catch (error) {
      hide();
      message.error(error.message);
      setBlockEditPreview(false);
      return false;
    }
  }

  const getProductInventory = (product_) => {
    const inventory_ = {
      existing: 0,
      available: 0,
      commited: 0,
      blocks: 0,
      slabs: 0,
      slabs_commited: 0,
    };

    product_.blocks.forEach((block) => {
      inventory_.blocks += 1;
      block.slabs.forEach((slab) => {
        inventory_.slabs += 1;
        inventory_.existing += slab.existing;
        inventory_.available += slab.available;
        inventory_.commited += slab.commited;
        if (slab.commited > 0) {
          inventory_.slabs_commited += 1;
        }
      });
    });

    return inventory_;
  };

  useEffect(() => {
    if (!product?.id) return;
    setTitle(
      <Spin spinning={loading}>
        <div>
          <strong>
            {product.name} / {product.sku}
          </strong>
          <br></br>
          <span className="description-text">
            <Badge className="bigbadgedot" color={stc(product.color)} />{" "}
            {product.material} , &nbsp; {product.family} /{product.subfamily}{" "}
            &nbsp; | &nbsp; {inventory.blocks} blocks, &nbsp;{" "}
            <u>
              {inventory.slabs} slabs available ({inventory?.available?.toFixed(2)})
            </u>
            , {inventory.slabs_commited} slabs commited ({inventory?.commited?.toFixed(2)})
            <br></br>
            {product?.tags?.split(",")?.map((item, i) => (
              <Tag color="#2F54EB" key={i}>
                <b style={{color:'white'}}>{item}</b>
              </Tag>
            ))}
          </span>{" "}
          <div style={{ float: "right" }}>
           { getUser().role !== "STONECLUB" && <Button type="link" onClick={() => setProductEditPreview(true)}>
              <EditOutlined />
            </Button>}
            <Button type="link" onClick={() => getProduct(id)}>
              <ReloadOutlined />
            </Button>
          </div>
        </div>
      </Spin>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventory, loading]);

  useEffect(() => {
    if (!product?.id) return;
    setInventory(getProductInventory(product));
  }, [product]);

  const getProduct = async (id) => {
    setLoading(true);
    try {
      const response = await marbleApi.http.get(
        marbleApi.urls.products.get(id)
      );
      if (response) {
        setProduct(response);
      }
    } catch (error) {
      message.error(error?.message);
    }
    setLoading(false);
  };

  const WareHouseTags = (block) => {
    let warehouseList = new Set();
    block.slabs.forEach((slab) => {
      warehouseList.add(slab.warehouse);
    });

    return Array.from(warehouseList).map((warehouse) => {
      return (
        <Tag key={warehouse} color="rgb(250, 173, 20)" style={{ margin: 5 }}>
          <strong>{warehouses[warehouse]}</strong>
        </Tag>
      );
    });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Collapse bordered={false} style={{ padding: 20 }}>
          {product?.blocks.map((block) => (
            <Panel
              header={
                <div>
                  📦 Block : <strong>{block.blockId}</strong>,{" "}
                  <u>{block.slabs.length} slabs</u>{" "}
                  {block?.shopifyHandle && (
                    <Tooltip title="View on Shopify">
                      <a
                        href={`https://marbleunlimitedinc.com/collections/materials/products/${block?.shopifyHandle}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: 10 }}
                      >
                        <LinkOutlined />
                      </a>
                    </Tooltip>
                  )}
                 
                  {"   "} &nbsp; &nbsp; <DollarOutlined /> {" "} 
                  { 
                  getUser().role === "STONECLUB" 
                    ? `Price $${block?.price_retail || product.price_retail }`
                    : `Retail $${block?.price_retail || product.price_retail} / Professional $${block?.price_professional || product.price_professional} / Fabricator $${block?.price_wholesale|| product.price_wholesale} / Wholesale $${block?.price_affiliate|| product.price_affiliate}`
                  }

                  <div style={{ float: "right" }}>
                    {WareHouseTags(block)}</div>
                  {getUser().email === "jmijares@marbleunlimitedinc.com" &&
                  <Button type="link" onClick={() => {
                    setBlockId(block.id)
                    setBlockEditPreview(true)
                  }}>
              <EditOutlined />
            </Button>
                  }
                </div>
              }
              key={block.id}
            >
              <br></br>
              <Image.PreviewGroup>
                {block.images.map((image) => (
                  <Image width={200} src={image.url} key={image.id} />
                ))}
              </Image.PreviewGroup>
              <div style={{ marginTop: 10 }}>
                <Slabs
                  slabs={block.slabs}
                  nested={nested}
                  product_id={id}
                  product={product}
                  getProduct={getProduct}
                  callback={callback}
                  block={block}
                />
              </div>
            </Panel>
          ))}
        </Collapse>
      </Spin>
      <ProductEdit
        productEditPreview={productEditPreview}
        setProductEditPreview={setProductEditPreview}
        id={id}
        product={product}
        getProduct={getProduct}
      />
       <ModalForm
            title="Upadte Block"
            width="400px"
            visible={blockEditPreview}
            onVisibleChange={setBlockEditPreview}
            onFinish={async (value) => {
              await handleUpdate(value);
            }}
            submitter={{
              searchConfig: {
                submitText: "Update",
              },
            }}
          >
                  <ProFormDigit
                    name="price_retail"
                    label="Retail"
                  />
                  <ProFormDigit
                    name="price_professional"
                    label="Professional"
                  />
                  <ProFormDigit
                    name="price_wholesale"
                    label="Fabricator"
                  />
                  <ProFormDigit
                    name="price_affiliate"
                    label="Wholesale"
                  />
        </ModalForm>
    </>
  );
}
