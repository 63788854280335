import marbleApiService from "../../services/http/marbleApi";
import { PlusOutlined, StarFilled } from "@ant-design/icons";
import { message, Modal, Upload, Tooltip } from "antd";
import React, { useState,useEffect } from "react";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function ImageUploadShopify(props) {
  const { callback, limit, images, block } = props;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState(images);
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    setFileList(images)
  }, [images]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadFile = async (componentsData) => {
    const hide = message.loading("uploading...");
    let formData = new FormData();
    formData.append("file", componentsData.file);
    formData.append("product_id", block?.shopifyProductId);
    formData.append("variant_id", block?.shopifyBlockId);
    formData.append("blockId", block?.id);

    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          componentsData.onProgress({ percent: progress });
        },
      };
      const response = await marbleApiService.http.post(
        marbleApiService.urls.asset.uploadShopify,
        formData,
        config
      );
      hide();
      /*  setFileList([
        {
          uid: 'na',
          name: '',
          status: 'done',
          url: response?.body?.url,
        },
      ]); */
      message.success("uploaded successfully");
      if (callback) callback("upload", response?.body);
      componentsData.onSuccess();
    } catch (error) {
      hide();
      componentsData.onError("Error uploading image");
      message.error(error.message);
    }
  };

  const removeFile = (file) => {
    const hide = message.loading("removing...");

    marbleApiService.http
      .post(marbleApiService.urls.asset.removeShopify, {
        product_id: block?.shopifyProductId,
        image_id: file.prefix,
      })
      .then((data) => {
        if (callback) callback("delete", null);
        hide();
        message.success("removed successfully");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <>
      <Upload
        customRequest={uploadFile}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={removeFile}
        onChange={handleChange}
        onDownload={(file) => {
          const hide = message.loading("updating...");

          marbleApiService.http
            .post(marbleApiService.urls.asset.updateShopify, {
              product_id: block?.shopifyProductId,
              image_id: file.prefix,
              position: 1,
            })
            .then((data) => {
              if (callback) callback("delete", null);
              hide();
              message.success("updated successfully");
            })
            .catch((error) => {
              message.error(error.message);
            });
        }}
        className="image-upload"
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: (
            <Tooltip title={"Set as Primary Image"}>
              <StarFilled style={{ color: "yellow" }} />
            </Tooltip>
          ),
        }}
      >
        {fileList.length >= limit ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
