import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import {
  Statistic,
  Switch,
  Button,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Tag,
} from "antd";
import { getFinalPrice, priceforcreate } from "../../../helpers/order";
import {
  unionBy,
  uniqBy,
  differenceBy,
} from "lodash";
import { useContext, useEffect, useState } from "react";
import appContext from "../../../context/app-context";
import { getUser } from "../../../helpers";
import marbleApi from "../../../services/http/marbleApi";
import Products from "../../products";
import styles from "../style.less";

const { Option } = Select;

let tempSlabs = {};

function ProductsTab(props) {
  const context = useContext(appContext);

  const [selectedRowsState, setSelectedRows] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [updated, setupdated] = useState(0);
  const [slabEdit] = useState([]);
  const [cancel] = useState(0);

  const { callback, order, syncOrder } = props;

  const [slabs, setSlabs] = useState(context?.cart || []);

  useEffect(() => {
    if (callback) callback(slabs);
  }, [callback, slabs]);

  useEffect(() => {
    if (order) setSlabs(order?.orderSlabs);
  }, [order, cancel]);


  useEffect(() => {
    setupdated(updated + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slabs]);

  const updateSlabs = async () => {
      if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
        message.error("only order owner can make changes");
      }
    else {
      if (!order) {
        let temp = slabs;
        for (const i in tempSlabs) {
          temp = temp.concat(tempSlabs[i]);
        }
        setSlabs(uniqBy(temp, "slabId"));
      } else {
        let data = [];
        for (const i in tempSlabs) {
          data = data.concat(tempSlabs[i]);
        }
        const hide = message.loading("...adding");
        try {
          await marbleApi.http.post(
            marbleApi.urls.orderSlabs.create,
            {
              slabs: data,
              orderid: order?.id,
            }
          );
          message.success("slab added");
          await syncOrder();
        } catch (error) {
          hide();
          message.error(error.message);
          return false;
        }
      }
    }

  };

  const setTempSlabs = (newSlabs, id) => {
    tempSlabs[id] = unionBy(newSlabs, tempSlabs[id], "slabId");
  };

  //deleting selected
  const handleDelete = async (data) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    } else {
      const hide = message.loading("Updating");
      try {
        await marbleApi.http.post(
          marbleApi.urls.orderSlabs.delete(order?.id),
          data,
        );
        hide();
        message.success("Deleted");
        await syncOrder();
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    }
  };

  //handle edit
  const handleEdit = async (data) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("Updating");

      try {
        await marbleApi.http.post(
          marbleApi.urls.orderSlabs.update(order?.id),
          data
        );
        hide();
        message.success("Updated successsfully");
        await syncOrder();
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    }
  };

  return (
    <>
      {" "}
      <ProTable
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            render: (dom, entity) => {
              return order ? <p>{entity.Slab?.name}</p> : <span>{dom}</span>;
            },
          },
          {
            title: "Block No.",
            dataIndex: "blockIdSAP",
            valueType: "textarea",
            render: (dom, entity) => {
              return <span>{order ? entity.Slab?.blockIdSAP : dom}</span>;
            },
          },
          {
            title: "Slab No.",
            dataIndex: "slabId",
            valueType: "textarea",
            render: (dom, entity) => {
              return <span>{order ? entity.Slab?.slabId : dom}</span>;
            },
          },
          {
            title: "Size",
            dataIndex: "size",
            valueType: "textarea",
            render: (dom, entity) => {
              return order
                ? `${
                    entity.Slab?.size
                      ? entity.Slab?.size
                      : entity.Slab?.existing
                  }`
                :   `${entity.size ? entity.size : entity.existing}`;
            },
          },
          {
            title: "Dimension",
            dataIndex: "dimensions",
            valueType: "textarea",
            render: (dom, entity) => {
              if (order && entity.Slab?.height && entity.Slab?.width) {
                return (
                  <span>
                    {entity.Slab?.height} x {entity.Slab?.width}
                  </span>
                );
              } else {
                return (
                  <span>
                    {entity.height || "na"} x {entity.width || "na"}
                  </span>
                );
              }
            },
          },

          {
            title: "Price Type",
            dataIndex: "dis",
            valueType: "select",
            render: (dom, entity) => {
              return order ? (
                <Select
                  style={{ width: 120 }}
                  value={entity.priceType}
                  bordered={false}
                  onChange={(value) => {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.slabid === entity.slabid
                      );
                      copy[index].price.price = entity.price[`${value}`];
                      copy[index].priceType = value;
                      copy[index].priceMain = entity.price[`${value}`];
                      copy[index].finalPrice = getFinalPrice(copy[index]);
                      slabEdit.push(entity.slabid);
                      return copy;
                    });
                  }}
                >
                  <Option value="retail">Retail</Option>
                  <Option value="professional">Professional</Option>
                  <Option value="wholesale">Fabricator</Option>
                  <Option value="affiliate">Wholesale</Option>
                </Select>
              ) : (
                <Select
                  style={{ width: 120 }}
                  defaultValue="retail"
                  value={entity.priceType}
                  bordered={false}
                  onChange={(value) => {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.id === entity.id
                      );
                      copy[index].price.price = entity.price[`${value}`];
                      copy[index].priceType = value;
                      return copy;
                    });
                  }}
                >
                  <Option value="retail">Retail</Option>
                  <Option value="professional">Professional</Option>
                  <Option value="wholesale">Fabricator</Option>
                  <Option value="affiliate">Wholesale</Option>
                </Select>
              );
            },
          },

          {
            title: "Price / sqf",
            dataIndex: "price",
            valueType: "textarea",
            render: (dom, entity) => {
              return order ? (
                <InputNumber
                  bordered={false}
                  defaultValue={entity.priceMain}
                  value={entity.priceMain}
                  onChange={(value) => {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.slabid === entity.slabid
                      );
                      copy[index].price.price = value;
                      copy[index].price[`${entity.priceType}`] = value;
                      copy[index].priceMain = value;
                      copy[index].finalPrice = getFinalPrice(copy[index]);
                      slabEdit.push(entity.slabid);
                      return copy;
                    });
                  }}
                  controls={false}
                  className="productDiscountInput"
                  prefix="$"
                />
              ) : (
                <InputNumber
                  bordered={false}
                  defaultValue={entity.price.price}
                  value={entity.price.price}
                  onChange={(value) => {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.id === entity.id
                      );
                      copy[index].price.price = value;
                      if(!entity.priceType){entity.priceType = "retail"}
                      copy[index].price[`${entity.priceType}`] = value;
                      return copy;
                    });
                  }}
                  controls={false}
                  className="productDiscountInput"
                  prefix="$"
                />
              );
            },
          },

          {
            title: "Discount / sqf",
            dataIndex: "discount",
            valueType: "textarea",
            render: (dom, entity) => {
              return order ? (
                <InputNumber
                  style={{ width: 100 }}
                  bordered={false}
                  value={entity.discount}
                  onChange={(value) => {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.slabid === entity.slabid
                      );
                      copy[index].discount = value;
                      copy[index].finalPrice = getFinalPrice(copy[index]);
                      slabEdit.push(entity.slabid);
                      return copy;
                    });
                  }}
                  controls={false}
                  className="productDiscountInput"
                  addonAfter={
                    <Select
                      defaultValue={entity.discountType || "amount"}
                      value={entity.discountType || "amount"}
                      style={{ width: 60 }}
                      bordered={false}
                      onChange={(value) => {
                        setSlabs((slabs) => {
                          const copy = [...slabs];
                          const index = copy.findIndex(
                            (slab) => slab.slabid === entity.slabid
                          );
                          copy[index].discountType = value;
                          copy[index].finalPrice = getFinalPrice(copy[index]);
                          slabEdit.push(entity.slabid);
                          return copy;
                        });
                      }}
                    >
                      <Option value="percent">%</Option>
                      <Option value="amount">$</Option>
                    </Select>
                  }
                />
              ) : (
                <InputNumber
                  style={{ width: 100 }}
                  bordered={false}
                  defaultValue={entity.discount}
                  onChange={(value) => {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.id === entity.id
                      );
                      copy[index].discount = value;
                      return copy;
                    });
                  }}
                  controls={false}
                  className="productDiscountInput"
                  addonAfter={
                    <Select
                      defaultValue={entity.discountType || "amount"}
                      style={{ width: 60 }}
                      bordered={false}
                      onChange={(value) => {
                        setSlabs((slabs) => {
                          const copy = [...slabs];
                          const index = copy.findIndex(
                            (slab) => slab.id === entity.id
                          );
                          copy[index].discountType = value;
                          return copy;
                        });
                      }}
                    >
                      <Option value="percent">%</Option>
                      <Option value="amount">$</Option>
                    </Select>
                  }
                />
              );
            },
          },

          {
            title: "Final Price",
            dataIndex: "finalPrice",
            valueType: "textarea",
            render: (dom, entity) => {
              return (
                <Statistic value={entity.finalPrice || priceforcreate(entity)} prefix="$" />
              );
            },
          },

          {
            title: "Hold",
            dataIndex: "hold",
            valueType: "boolean",
            render: (dom, entity) => {
              return (
                <Switch
                  className={styles.OrderProductInput}
                  value={dom}
                  checked={order ? entity.Slab?.hold : entity.hold}
                  onChange={(e) => {
                    if (order) {
                      handleEdit({
                        hold: e,
                        slabs: [entity],
                        user: getUser().name,
                      });
                    } else {
                      setSlabs((slabs) => {
                        const copy = [...slabs];
                        const index = copy.findIndex(
                          (slab) => slab.id === entity.id
                        );
                        copy[index].hold = e;
                        return copy;
                      });
                    }
                  }}
                />
              );
            },
          },

          {
            title: "",
            dataIndex: "operation",
            key: "operation",
            valueType: "option",
            render: (dom, entity) => [
              <span key={1}>
                {entity.damagedPercentage ? (
                  <Tag color="red">
                    Damaged {entity.damagedPercentage + "%"}
                  </Tag>
                ) : null}
              </span>,
              <Button
                type="text"
                danger
                key={2}
                onClick={() => {
                  if (order) {
                    handleDelete([entity]);
                  } else {
                    setSlabs((slabs) => {
                      const copy = [...slabs];
                      const index = copy.findIndex(
                        (slab) => slab.id === entity.id
                      );
                      copy.splice(index, 1);
                      return copy;
                    });
                  }
                }}
              >
                <DeleteOutlined />
              </Button>,
            ],
          },
        ]}
        key="slabid"
        rowKey={order ? `slabid` : `id`}
        headerTitle={false}
        search={false}
        options={false}
        dataSource={slabs}
        pagination={false}
        toolBarRender={() => {
          return (
            <>
              {order && slabEdit.length > 0 && (
                <>
                  <Button
                    key={1}
                    onClick={async () => await syncOrder()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    key={2}
                    onClick={() => {
                      handleEdit({ update: slabs, unique: slabEdit });
                    }}
                  >
                    Save
                  </Button>
                </>
              )}

              <Button
                type="link"
                key={2}
                onClick={() => {
                  tempSlabs = [];
                  setModalVisible(true);
                }}
              >
                <PlusOutlined /> Add Products
              </Button>
            </>
          );
        }}
        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }}
        tableAlertRender={({
          selectedRowKeys,
          selectedRows,
          onCleanSelected,
        }) => (
          <Space size={24}>
            <span>
              {selectedRowKeys.length} products selected
              <button type="button" style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                Clear Selection
              </button>
            </span>
          </Space>
        )}
        tableAlertOptionRender={() => {
          return (
            <Space size={16}>
              <Select
                onChange={(value) => {
                  if (order) {
                    handleEdit({
                      hold: value,
                      slabs: selectedRowsState,
                      user: getUser().name,
                    });
                  } else {
                    selectedRowsState.forEach((i) => {
                      setSlabs((slabs) => {
                        const copy = [...slabs];
                        const index = copy.findIndex(
                          (slab) => slab.id === i.id
                        );
                        copy[index].hold = value;
                        return copy;
                      });
                    });
                  }
                }}
                placeholder="hold"
                style={{ width: 100 }}
              >
                <Option value={true}>hold</Option>
                <Option value={false}>unhold</Option>
              </Select>
              {order && (
                <>
                  <Select
                    onChange={(value) => {
                      selectedRowsState.forEach((i) => {
                        setSlabs((slabs) => {
                          const copy = [...slabs];
                          const index = copy.findIndex(
                            (slab) => slab.slabid === i.slabid
                          );
                          copy[index].price.price = i.price[`${value}`];
                          copy[index].priceType = value;
                          copy[index].priceMain = i.price[`${value}`];
                          if (order) {
                            copy[index].finalPrice = getFinalPrice(copy[index]);
                            slabEdit.push(i.slabid);
                          }

                          return copy;
                        });
                      });
                    }}
                    placeholder="Price Type"
                    style={{ width: 120 }}
                  >
                    <Option value="retail">Retail</Option>
                    <Option value="professional">Professional</Option>
                    <Option value="wholesale">Fabricator</Option>
                    <Option value="affiliate">Wholesale</Option>
                  </Select>
                  <InputNumber
                    style={{ width: 140 }}
                    placeholder="Discount"
                    onChange={(value) => {
                      selectedRowsState.forEach((i) => {
                        setSlabs((slabs) => {
                          const copy = [...slabs];
                          const index = copy.findIndex(
                            (slab) => slab.slabid === i.slabid
                          );
                          copy[index].discount = value;
                          copy[index].finalPrice = getFinalPrice(copy[index]);
                          slabEdit.push(i.slabid);
                          return copy;
                        });
                      });
                    }}
                    controls={false}
                    addonAfter={
                      <Select
                        placeholder="$"
                        style={{ width: 50 }}
                        onChange={(value) => {
                          selectedRowsState.forEach((i) => {
                            setSlabs((slabs) => {
                              const copy = [...slabs];
                              const index = copy.findIndex(
                                (slab) => slab.slabid === i.slabid
                              );
                              copy[index].discountType = value;
                              copy[index].finalPrice = getFinalPrice(
                                copy[index]
                              );
                              slabEdit.push(i.slabid);
                              return copy;
                            });
                          });
                        }}
                      >
                        <Option value="percent">%</Option>
                        <Option value="amount">$</Option>
                      </Select>
                    }
                  />
                </>
              )}
              <Button
                type="text"
                danger
                onClick={() => {
                  if (order) {
                    handleDelete(selectedRowsState);
                  } else {
                    const intersection = differenceBy(
                      slabs,
                      selectedRowsState,
                      "id"
                    );
                    setSlabs(intersection);
                  }
                }}
              >
                <CloseCircleOutlined /> Remove
              </Button>
            </Space>
          );
        }}
      />
      <Modal
        title="Add Products"
        centered
        maskClosable={false}
        open={modalVisible}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{position:"fixed", bottom:"5px", right:"10vw"}}
            onClick={() => {
              updateSlabs(tempSlabs);
              setModalVisible(false);
            }}
          >
            Add Products
          </Button>,
        ]}
        onCancel={() => setModalVisible(false)}
        width="85%"
        style={{ height: "80%" }}
      >
        <Products callback={setTempSlabs} />
      </Modal>
    </>
  );
}

export default ProductsTab;
