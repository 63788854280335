import React from "react";
import { Tooltip,message,Button } from "antd";
import marbleApi from "../../services/http/marbleApi";
import "./index.less";

export default function SapSync({user}) {
  const syncing = "primary";
  const buttonText = "Sap Sync";

    const syncSap = async () => {
    try {
      const response = await marbleApi.http.post(marbleApi.urls.sap.sync, {
        user,
      });
        if (response) {
          message.success(response);
        }
    } catch (error) {
      message.error(error?.message);
    }
   }
  return (
    <Tooltip className="sync_zoho" title={"Sync stonepro with Sap"}>
      <Button type={syncing} onClick={syncSap}>{buttonText}</Button>
    </Tooltip>
  );
}
