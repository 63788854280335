import { BASE_URL } from "../../constants";
import { getFromLocalStorage, refreshToken } from "../../helpers";
import { HttpService } from "./index";

/**
 * HTTP client side possible errrors.
 */
const API_ERRORS = {
  timeout: {
    fault: {
      message: "Request Timeout",
      description: "Request took longer than expected, Try Again!",
    },
  },
  common: (error, message) => {
    return {
      fault: {
        message: error?.message || message || "Unknown Error Occurred.",
        description:
          error?.message || message || "Unknown Error Occurred. Try Again!",
      },
    };
  },
};

/**
 * Resolver marble Api response Error or Success
 * @param {*} response
 */
export const marbleApiResponseResolver = (data, raw = null) => {
  let error = false;

  /**
   * @Note We will revalidate the error condition once again according to cases
   */
  if (raw && raw.response && raw.response.status === 500) {
    error = true;
    // if content type is not json mean some unhandled error occured.
    if (
      raw.response.headers["content-type"].toLowerCase() !== "application/json"
    ) {
      return [true, API_ERRORS.common({}, null)];
    }
  }

  // @TODO - ONCE WE WILL HAVE STANDERED ERROR PATTERN IN BACKEND ADD switch CONDITIONS HERE
  return [error, data];
};

/**
 * Handle error which occured in Client Side.
 */
const handleRequestError = (error, message) => {
  return error.timeout ? API_ERRORS.timeout : API_ERRORS.common(error, message);
};

/**
 * marble API Error Response Handler
 * @param {*} error
 * @returns
 */
export const errorResponseHandler = async (error) => {
  try {
    let data = null;
    if (error.response && error.response.data) {
      // pass direct error response object from backend
      data = error.response.data;

      if (data?.message === "TokenExpiredError") {
        // if token expired then clear the token and redirect to login page
        const tokenrefreshed = await refreshToken();
        // eslint-disable-next-line no-restricted-globals
        if (tokenrefreshed) location.reload();
      }
    } else {
      // get client error if any and map client side error mapped object
      const mes = error.message || "Unknown Error";
      data = handleRequestError(error.request, mes);
    }
    const resp = marbleApiResponseResolver(data, error);
    return Promise.reject(resp[1]);
  } catch (e) {
    return Promise.reject(error);
  }
};

/**
 * Success Response Handler
 * @param {*} response
 * @returns
 */
export const successResponseHandler = (response) => {
  try {
    const [error, resp] = marbleApiResponseResolver(response.data);
    if (error) return Promise.reject(resp);
    return Promise.resolve(resp);
  } catch (e) {
    return Promise.resolve(response);
  }
};

/**
 * marble APi Service Request Interceptor.
 * @param {*} config
 * @returns
 */
export const requestInterceptor = async (config) => {
  const conf = { ...config };
  conf.headers["Bypass-Tunnel-Reminder"] = `true`;
  const token = getFromLocalStorage("user")?.accessToken;
  conf.baseURL = BASE_URL;
  if (token && token !== "") conf.headers.Authorization = `Bearer ${token}`;
  return conf;
};

/**
 * All endpoints used in this Service
 */
const endpoints = {
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    verify: "/auth/verify-registeration",
    forgotPassword: "/auth/forgot-password",
    refreshToken: "/auth/refreshToken",
    registrationEmail: "/auth/sendEmail",
  },
  user: {
    get: (id) => `/user/${id}`,
    update: (id) => `/user/update/${id}`,
    list: "/user/list",
  },
  asset: {
    upload: "/asset/upload",
    remove: "/asset/remove",
    uploadShopify: "asset/shopify/upload",
    removeShopify: "/asset/shopify/remove",
    updateShopify: "/asset/shopify/update",
    uploadSlabShopify: "/asset/slabShopify/upload",
  },
  products: {
    get: (id) => `/product/${id}`,
    list: "/product/list",
    options: "/product/options",
    updateSlab: `/product/slab/update`,
    updateBlock: `/product/block/update`,
    listBlocks: "product/block/list",
    priceUpdate: "product/price/update",
    updateProduct: `/product/product/update`,
  },
  utils: {
    listLogs: "/utils/logs/list",
  },
  order: {
    get: (id) => `/order/${id}`,
    update: (id) => `/order/update/${id}`,
    list: "/order/list",
    create: "/order",
    delete: (id) => `/order/${id}`,
  },
  client: {
    get: (id) => `/client/${id}`,
    update: (id) => `/client/update/${id}`,
    list: "/client/list",
    create: "/client",
    industries: "/client/industries",
  },
  quote: {
    get: (id) => `/quote/${id}`,
    update: (id) => `/quote/update/${id}`,
    list: "/quote/list",
    create: "/quote/create",
    send: "/quote/send",
  },
  payment: {
    get: (id) => `/payment/${id}`,
    update: (id) => `/payment/update/${id}`,
    list: "/payment/list",
    create: "/payment",
  },
  saleOrder: {
    create: "saleOrder/create",
  },
  invoice: {
    create: "invoice/create",
    update: (id) => `/invoice/update/${id}`,
  },
  deniedMatarial: {
    create: "deniedMaterial/create",
    list: "deniedMaterial/list",
  },
  newArrivals: {
    list: "newArrivals/list",
  },
  specialProducts: {
    create: "specialProducts/create",
    delete: (id) => `/specialProducts/delete/${id}`,
    update: (id) => `/specialProducts/edit/${id}`,

  },
  orderSlabs: {
    create: "orderSlabs/create",
    delete: (id) => `/orderSlabs/delete/${id}`,
    update: (id) => `/orderSlabs/edit/${id}`,


  },
  dashboard: {
    list:"/dashboard/read",
  },
  zoho: {
    search: "/zoho/searchLead",
    convertLead: "/zoho/convertLead",
    sync: "/zoho/sync",
    searchAccount: "/zoho/search-account",
    mergeTree: "/zoho/merge-tree",
    fetchContacts: "/zoho/fetch-contacts",
    selectContact:"/zoho/select-contact",
    create: "/zoho/create-account",
    getAccount: (id) => `/zoho/account/${id}`,
  },
  sap: {
    sync: "/sap/sync",
    searchVendor: "/sap/searchVendor",
  },
  shopify: {
    sync: "/shopify/sync",
  }
};

/**
 * Service which we can be consume to call marble api endpoints.
 */
class MarbleApiService extends HttpService {
  /**
   * Add request interceptor(handle token and any other on the fly config changes).
   * Set success and error handler & add all the possible endpoints for the service.
   */
  constructor() {
    super(requestInterceptor, successResponseHandler, errorResponseHandler);
    this.urls = endpoints;
  }
}

export const marbleApiService = new MarbleApiService();
export default marbleApiService;
