import { Table } from "antd";
import React from "react";

function Emails(props) {
  const { order } = props;
  const columns = [
    {
      title: "Subject",
      tip: "Email Subject",
      dataIndex: "subject",
      sorter: true,
      render: (dom) => {
        return <strong>{dom}</strong>;
      },
    },
    {
      title: "Body",
      dataIndex: "body",
      valueType: "textarea",
    },
    {
      title: "Sent At",
      dataIndex: "createdAt",
      valueType: "textarea",
    },
    {
      title: "Attachments",
      dataIndex: "operation",
      key: "operation",
      valueType: "option",
      render: (dom, entity) =>
        entity.attachments.map((attachment, i) => {
          return (
            <a target={"_blank"} rel="noreferrer" key={i} href={attachment.url}>
              {attachment.filename}
            </a>
          );
        }),
    },
  ];

  return (
    <Table
      pagination={false}
      loading={false}
      dataSource={order.emails}
      columns={columns}
    />
  );
}

export default Emails;
