import ProTable from "@ant-design/pro-table";

function ClientOrders({ order, setSelectedOrder }) {
  return (
    <ProTable
      //------------------------------------------------------------
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          sorter: true,
          valueType: "textarea",
        },
        {
          title: "Opportunity name",
          dataIndex: "description",
          sorter: true,
        },
        {
          title: "Materials",
          dataIndex: "totalSize",
          search: false,
          sorter: true,
          hideInForm: true,
          renderText: (val) => `${val} Sqf.`,
        },
        {
          title: "Amount",
          dataIndex: "totalPrice",
          search: false,
          sorter: true,
          hideInForm: true,
          renderText: (val) => `$ ${val}`,
        },
        {
          title: "Payment",
          dataIndex: "percent",
          search: false,
          sorter: true,
          hideInForm: true,
          renderText: (val) => `NA`,
        },
        {
          title: "Created On",
          sorter: true,
          search: false,
          dataIndex: "updatedAt",
          valueType: "dateTime",
        },
      ]}
      //-------------------------------------------------------------

      headerTitle={false}
      search={false}
      options={false}
      rowKey="id"
      dataSource={order?.client.orders}
      pagination={false}
      rowSelection={{
        onChange: (_, selectedRows) => {
          setSelectedOrder(selectedRows);
        },
      }}
    />
  );
}

export default ClientOrders;
