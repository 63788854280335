import {
  CheckCircleFilled,
  DollarCircleFilled,
  FileTextOutlined,
  FlagFilled,
  MessageFilled,
  DislikeFilled
} from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";

function OrderStatus(props) {
  const { orderStatus } = props;
  let t = <></>;
  switch (orderStatus) {
    case "quote":
      t = (
        <Tag>
          <MessageFilled /> Quote
        </Tag>
      );
      break;
    case "payment":
      t = (
        <Tag>
          <DollarCircleFilled /> Payment
        </Tag>
      );
      break;
    case "sale":
      t = (
        <Tag>
          <FlagFilled style={{ color: "orange" }} /> Sale Order
        </Tag>
      );
      break;
    case "complete":
      t = (
        <Tag>
          <CheckCircleFilled style={{ color: "green" }} /> Complete
        </Tag>
      );
      break;
    case "draft":
      t = (
        <Tag>
          {" "}
          <FileTextOutlined style={{ color: "blue" }} /> Draft
        </Tag>
      );
      break;
      case "lost":
        t = (
          <Tag>
            <DislikeFilled style={{ color: "red" }} />{" "} Close Lost
          </Tag>
        );
        break;
    default:
      break;
  }
  return t;
}

export default OrderStatus;
